import { graphql, useStaticQuery } from 'gatsby';

import { getProperty } from 'utils';

import { useFlatten } from './use-flatten';

export const useDownloadAreas = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryDownloadAreasContents';

	return useFlatten(getProperty(results, key) || {});
};

export const useDownloadAreaById = (value) =>
	useDownloadAreas().find((downloadArea) => {
		const downloadAreaId = getProperty(downloadArea, 'id');
		return downloadAreaId === value;
	});

const query = graphql`
	query useDownloadAreasQuery {
		squidex {
			queryDownloadAreasContents(top: 999, orderby: "data/rank/iv") {
				id
				data {
					name {
						de
					}
				}
			}
		}
	}
`;
