import { isClient } from './misc';

//
// Scrolls to a dom element smoothly
//
export const scrollTo = (element) => {
  if (element instanceof HTMLElement) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

//
// Tries to read overall scroll offset
//
export const getScrollOffsetY = () => {
  if (!isClient) {
    return 0;
  }

  return typeof window.pageYOffset === 'number'
    ? window.pageYOffset
    : typeof document.documentElement.scrollTop === 'number'
    ? document.documentElement.scrollTop
    : document.body.scrollTop;
};

//
// Reads a computed style value
//
export const getStyleProperty = (element, property) => {
  if (!isClient) {
    return null;
  }

  const computedStyles = window.getComputedStyle(element);
  return computedStyles.getPropertyValue(property);
};

//
// Tries to detect resize changes of element
//
export const observeResize = (element, callback) => {
  if (typeof MutationObserver === 'undefined') {
    const eventName = 'DOMSubtreeModified';

    document.addEventListener(eventName, callback);
    return () => document.removeEventListener(eventName, callback);
  }

  if (typeof ResizeObserver === 'undefined') {
    const observer = new MutationObserver(callback);
    observer.observe(element, {
      characterData: true,
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }

  const observer = new ResizeObserver(callback);
  observer.observe(element);

  return () => observer.disconnect();
};
