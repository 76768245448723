import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticProject = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryProjectContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useProjectQuery {
		squidex {
			queryProjectContents {
				id
				data {
					content {
						de {
							buttonRequest
							buttonMunicipal
						}
					}

					info {
						de {
							availabilityFreeUnits
							availabilityNoFreeUnits
							availabilityMunicipal
							projectStatus
							buttonContact
						}
					}

					tags {
						de {
							title
						}
					}

					units {
						de {
							title
							noUnits
							cellsRent
							cellsHirePurchase
							cellsProperty
						}
					}

					folder {
						de {
							title
							noFolder
						}
					}

					downloads {
						de {
							title
							noDownloads
						}
					}

					furtherInformation {
						de {
							title
							noFurtherInformation
						}
					}
				}
			}
		}
	}
`;
