import { graphql, useStaticQuery } from 'gatsby';

import { getProperty } from 'utils';

import { useFlatten } from './use-flatten';

export const usePages = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryPagesContents';

	return useFlatten(getProperty(results, key) || {});
};

export const usePageById = (value) =>
	usePages().find((page) => {
		const pageId = getProperty(page, 'id');
		return pageId === value;
	});

export const usePageByAlias = (value) =>
	usePages().find((page) => {
		const pageAlias = getProperty(page, 'data.alias');
		return pageAlias === value;
	});

export const usePagesByMenu = (value) =>
	usePages().filter((page) => {
		const pageMenu = getProperty(page, 'data.menu');
		return pageMenu === value;
	});

const query = graphql`
	query usePagesQuery {
		squidex {
			queryPagesContents(
				top: 999
				orderby: "data/rank/iv"
				filter: "status eq 'Published'"
			) {
				id
				data {
					menu {
						iv
					}
					alias {
						iv
					}
					path {
						de
					}
					title {
						de
					}
				}
			}
		}
	}
`;
