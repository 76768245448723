export const isValid = (value) => {
  if (typeof value !== 'string') {
    return false;
  }

	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

export const toUri = (string) => {
	return isValid(string) ? `mailto:${string}` : null;
};

