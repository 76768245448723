import React, { createContext, useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ACCORDION_CONTEXT_DEFAULT = {
  openedId: null,
  isAccordionEnabled: false,
  toggleOpenedId: (/* id */) => {},
};

export const AccordionContext = createContext(ACCORDION_CONTEXT_DEFAULT);

export const Accordion = ({ children, openedId, onToggleExpandable }) => {
  const [currentOpenedId, setCurrentOpenedId] = useState(null);

  const toggleOpenedId = useCallback(
    (id) => {
      setCurrentOpenedId((oldId) => {
        const newId = oldId !== id ? id : null;
        onToggleExpandable(id);
        return newId;
      });
    },
    [onToggleExpandable]
  );

  const value = useMemo(
    () => ({
      openedId: currentOpenedId,
      isAccordionEnabled: true,
      toggleOpenedId,
    }),
    [currentOpenedId, toggleOpenedId]
  );

  useEffect(() => {
    if (typeof openedId !== 'string' && openedId !== null) {
      return;
    }

    setCurrentOpenedId(openedId);
  }, [openedId]);

  return <AccordionContext.Provider value={value}>{children}</AccordionContext.Provider>;
};

Accordion.defaultProps = {
  onToggleExpandable: () => {},
};

Accordion.propTypes = {
  openedId: PropTypes.string,
  onToggleExpandable: PropTypes.func,
};
