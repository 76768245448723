import React, { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { detectLanguage } from 'utils';

const LOCALES = {
  de: true,
};

const validateLocale = (locale) => {
  locale = typeof locale !== 'string' ? detectLanguage() : locale;

  if (locale in LOCALES) {
    return locale;
  }

  return DEFAULT_LOCALE;
};

export const ALL_LOCALES = Object.keys(LOCALES);
export const DEFAULT_LOCALE = (() => ALL_LOCALES.find((key) => !!LOCALES[key]))();

export const LocaleProvider = ({ locale: initialLocale, ...props }) => {
  const [locale, setLocale] = useState(validateLocale(initialLocale));
  const validateSetter = useCallback((locale) => {
    locale = validateLocale(locale);
    setLocale(locale);
  }, []);

  const value = useMemo(
    () => ({
      locale,
      locales: ALL_LOCALES,
      setLocale: validateSetter,
      defaultLocale: DEFAULT_LOCALE,
    }),
    [locale, validateSetter]
  );

  return <LocaleContext.Provider value={value} {...props} />;
};

LocaleProvider.defaultProps = {
  locale: DEFAULT_LOCALE,
};

LocaleProvider.propTypes = {
  locale: PropTypes.string,
};

export const LocaleContext = createContext();
