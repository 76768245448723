import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticContact = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryContactContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useContactQuery {
		squidex {
			queryContactContents {
				id
				data {
					seo {
						de {
							title
							description
						}
					}

					header {
						de {
							title
						}
					}

					content {
						de {
							title
							text
							backgroundImages {
								localFile {
									childImageSharp {
										gatsbyImageData(layout: FULL_WIDTH, quality: 100)
									}
								}
								...SquidexImageLocalFileProps
							}
						}
					}

					hoursContent {
						de {
							title
						}
					}

					contactContent {
						de {
							title
						}
					}

					formFields {
						de {
							name
							alias
							isRequired
							isHalfWidth
							type
						}
					}
					formSubmit {
						de
					}
					formRecaptcha {
						de
					}
					formBusyTitle {
						de
					}
					formBusyMessage {
						de
					}
					formSuccessTitle {
						de
					}
					formSuccessMessage {
						de
					}
					formFailureTitle {
						de
					}
					formFailureMessage {
						de
					}
				}
			}
		}
	}
`;
