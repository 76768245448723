import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticGtc = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryGtcContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useGtcQuery {
		squidex {
			queryGtcContents {
				id
				data {
					seo {
						de {
							title
							description
						}
					}

					header {
						de {
							title
						}
					}

					content {
						de {
							title
							text
						}
					}

					file {
						iv {
							url
						}
					}
				}
			}
		}
	}
`;
