import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ArrowRightIcon } from 'icons'

import styles from './button.module.scss';

export const Button = ({
  wrapper: Wrapper,
  size,
  color,
  type,
  hover,
  children,
  className,
  ...props
}) => {
  const wrapperClassName = useMemo(
    () => [styles.wrapper, styles[size], styles[type], styles[color], hover ? styles.hover : ''].join(' '),
    [size, type, color, hover]
  );

  return (
    <Wrapper className={wrapperClassName} {...props}>
      <span className={styles.text}>{children}</span>
      {type === 'default' && (
        <span className={styles.icon}>
          <ArrowRightIcon className={styles.svg} />
        </span>
      )}
    </Wrapper>
  );
};

Button.defaultProps = {
  size: 'md',
  hover: true,
  color: 'primary',
  type: 'default',
  wrapper: 'button',
  onClick: () => {},
};

Button.propTypes = {
  hover: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'white', 'warning']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  type: PropTypes.oneOf(['default', 'minimal', 'rounded', 'outline']),
  wrapper: PropTypes.elementType,
};
