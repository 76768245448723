import React, { forwardRef, useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Input } from './input';

import { CloseIcon, MagnifierIcon } from 'icons';

import styles from './search.module.scss';

export const Search = forwardRef(
  ({ value, focus, placeholder, onInput, onChange }, ref) => {
    const [currentValue, setCurrentValue] = useState(value);
    const [isFocused, setIsFocused] = useState(false);

    const wrapperclassName = useMemo(
      () => [styles.wrapper, isFocused ? styles.focus : ''].join(' '),
      [isFocused]
    );

    const blurHandler = useCallback(() => setIsFocused(false), []);
    const focusHandler = useCallback(() => setIsFocused(true), []);

    const inputHandler = useCallback(
      (value) => {
        setCurrentValue(value);
        onInput(value);
      },
      [onInput]
    );

    const changeHandler = useCallback(
      (value) => {
        setCurrentValue(value);
        onChange(value);
      },
      [onChange]
    );

    const clearHandler = useCallback(() => {
      setCurrentValue('');
      onChange('');
      onInput('');
    }, [onChange, onInput]);

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    return (
      <div className={wrapperclassName}>
        <div className={styles.magnifier}>
          <MagnifierIcon className={styles.magnifierSvg} />
        </div>
        <div className={styles.input}>
          <Input
            ref={ref}
            focus={focus}
            value={currentValue}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onInput={inputHandler}
            onChange={changeHandler}
            placeholder={placeholder}
            paddingLeft={'3rem'}
            paddingRight={'2rem'}
          />
        </div>

        <div className={styles.clear}>
          {currentValue && (
            <button type="button" onClick={clearHandler} className={styles.clear}>
              <CloseIcon className={styles.clearSvg} />
            </button>
          )}
        </div>
      </div>
    );
  }
);

Search.defaultProps = {
  value: '',
  size: 'md',
  focus: false,
  placeholder: 'Search...',
  onInput: () => {},
  onChange: () => {},
};

Input.propTypes = {
  value: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  focus: PropTypes.bool,
  placeholder: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
};
