import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { getProperty, arrayToObject } from 'utils';

import { useFlatten } from './use-flatten';

export const useFocusAreas = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryFocusAreasContents';

	return useFlatten(getProperty(results, key) || {}, (focusAreas) =>
		focusAreas.map((focusArea) => {
			const pagePath = getProperty(focusArea, 'data.parent.0.data.path');
			const focusAreaPath = getProperty(focusArea, 'data.path');
			focusArea.data.path = `${pagePath}${focusAreaPath}`;

			return focusArea;
		})
	);
};

export const useFocusAreaById = (value) =>
	useFocusAreas().find((focusArea) => {
		const focusAreaId = getProperty(focusArea, 'id');
		return focusAreaId === value;
	});

export const useFocusAreaByAlias = (value) =>
	useFocusAreas().find((feature) => getProperty(feature, 'data.alias') === value);

export const useFocusAreasByAliases = (values) => {
	const valuesMap = useMemo(() => arrayToObject(values), [values]);

	return useFocusAreas().filter(
		(feature) => getProperty(feature, 'data.alias') in valuesMap
	);
};

const query = graphql`
	query useFocusAreasQuery {
		squidex {
			queryFocusAreasContents(top: 999, orderby: "data/rank/iv") {
				id
				data {
					name {
						de
					}
					alias {
						iv
					}
					path {
						de
					}
					parent {
						iv {
							data {
								path {
									de
								}
							}
						}
					}
					description {
						de
					}
					title {
						de
					}
					text {
						de
					}
					icon {
						de {
							localFile {
								publicURL
							}
							...SquidexImageLocalFileProps
						}
					}
					illustration {
						iv {
							localFile {
								publicURL
							}
							...SquidexImageLocalFileProps
						}
					}
					symbol {
						iv {
							localFile {
								publicURL
							}
							...SquidexImageLocalFileProps
						}
					}
					filterTitle {
						de
					}
				}
			}
		}
	}
`;
