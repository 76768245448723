import customWrapRootElement from './wrap-root-element';
import customWrapPageElement from './wrap-page-element';

import './src/styles/main.scss';

export const wrapRootElement = customWrapRootElement;
export const wrapPageElement = customWrapPageElement;

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  }
};

/*
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  // const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

  window.scrollTo(...(currentPosition || [0, 0]));
  return false;
};
*/
