import { forwardRef, useState, useEffect, useContext, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { TileLayer } from 'leaflet';

import { MapContext } from './map-container';
import { MAP_TILE_LAYER_DEFAULT_OPTIONS } from './options';

// export const MAP_TILE_LAYER_DEFAULT_OPTIONS = {
//   minZoom: 0,
//   maxZoom: 18,
//   attribution: null,
// };

export const MapTileLayer = forwardRef(({ url, options, onInitialized }, ref) => {
  const [tileLayer, setTileLayer] = useState(null);
  const map = useContext(MapContext);

  useImperativeHandle(ref, () => tileLayer, [tileLayer]);

  useEffect(() => {
    if (map === null) {
      return;
    }

    const tileLayer = new TileLayer(url, options);
    setTileLayer(tileLayer);

    map.addLayer(tileLayer);

    const teardown = onInitialized(map, tileLayer);

    return () => {
      if (typeof teardown === 'function') {
        teardown();
      }

      tileLayer.remove();
      setTileLayer(null);
    };
  }, [map, url, options, onInitialized]);

  return null;
});

MapTileLayer.defaultProps = {
  url: null,
  options: MAP_TILE_LAYER_DEFAULT_OPTIONS,
  onInitialized: (/* map, marker */) => {},
};

MapTileLayer.propTypes = {
  latLng: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.shape({
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    attribution: PropTypes.string,
  }),
  onInitialized: PropTypes.func,
};
