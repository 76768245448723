import React, { forwardRef } from 'react';
import { RadiobuttonCheckedIcon, RadiobuttonUncheckedIcon } from 'icons';

import { Checkbox, CheckboxPropTypes, CheckboxDefaultProps } from './checkbox';

export const Radiobutton = forwardRef((props, ref) => (
  <Checkbox
    {...props}
    type="radio"
    iconChecked={RadiobuttonCheckedIcon}
    iconUnchecked={RadiobuttonUncheckedIcon}
  />
));

Radiobutton.defaultProps = {
  ...CheckboxDefaultProps,
};

Radiobutton.propTypes = {
  ...CheckboxPropTypes,
};
