import React, {
  Children,
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { MD } from 'utils';
import { Text } from 'components';
import { useMatchMedia } from 'hooks';

import styles from './table.module.scss';

const TABLE_CONTEXT_DEFAULT = {
  headers: [],
  isTable: false,
  setHeaders: () => {},
};

export const TableContext = createContext(TABLE_CONTEXT_DEFAULT);

export const Table = ({ children, breakPoint }) => {
  const mediaQueries = useMemo(() => [`(min-width:${breakPoint}px)`], [breakPoint]);
  const [isTable] = useMatchMedia(mediaQueries);
  const [headers, setHeaders] = useState([]);
  const wrapperclassName = useMemo(
    () => [styles.table, isTable ? styles.isTable : ''].join(' '),
    [isTable]
  );
  const value = useMemo(() => ({ isTable, headers, setHeaders }), [isTable, headers]);

  return (
    <TableContext.Provider value={value}>
      <div className={wrapperclassName}>{children}</div>
    </TableContext.Provider>
  );
};

Table.defaultProps = {
  breakPoint: MD,
};

Table.propTypes = {
  breakPoint: PropTypes.number,
};

export const Thead = ({ children }) => <div className={styles.thead}>{children}</div>;

export const Tbody = ({ children }) => <div className={styles.tbody}>{children}</div>;

export const Tr = ({ children }) => (
  <div className={styles.tr}>
    {Children.map(children, (element, index) => cloneElement(element, { index }))}
  </div>
);

export const Th = ({ children, align, valign, nowrap }) => {
  const { setHeaders } = useContext(TableContext);

  const wrapperclassName = useMemo(
    () =>
      [styles.th, styles[align], styles[valign], nowrap ? styles.nowrap : ''].join(' '),
    [align, valign, nowrap]
  );

  useEffect(() => {
    setHeaders((headers) => [...headers, ...[children]]);
  }, [setHeaders, children]);

  return (
    <div className={wrapperclassName}>
      <Text size="xs" type="special" weight="bold">
        {children}
      </Text>
    </div>
  );
};

Th.defaultProps = {
  align: 'left',
  valign: 'middle',
  nowrap: false,
};

Th.propTypes = {
  index: PropTypes.number,
  nowrap: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  valign: PropTypes.oneOf(['top', 'middle', 'bottom']),
};

export const Td = ({ children, index, align, valign, nowrap }) => {
  const { isTable, headers } = useContext(TableContext);

  const wrapperclassName = useMemo(
    () =>
      [styles.td, styles[align], styles[valign], nowrap ? styles.nowrap : ''].join(' '),
    [align, valign, nowrap]
  );

  return isTable ? (
    <div className={wrapperclassName}>
      <Text size="xs">{children}</Text>
    </div>
  ) : (
    <div className={wrapperclassName}>
      <div className={styles.tdLabel}>
        <Text size="xs" type="special" weight="bold">
          {headers[index]}
        </Text>
      </div>
      <div className={styles.tdValue}>
        <Text size="xs">{children}</Text>
      </div>
    </div>
  );
};

Td.defaultProps = {
  align: 'left',
  valign: 'middle',
  nowrap: false,
};

Td.propTypes = {
  index: PropTypes.number,
  nowrap: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  valign: PropTypes.oneOf(['top', 'middle', 'bottom']),
};
