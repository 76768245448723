import PropTypes from 'prop-types';

import { useIsMounted } from 'hooks';

export const Conditional = ({ condition, wrapper, children, fallbackWrapper }) => {
  // note: always render SAME output for SSR & CSR contents,
  // thus the initial render will always render the fallback
  const isMounted = useIsMounted();

  return isMounted && condition
    ? wrapper(children)
    : typeof fallbackWrapper === 'function' ? fallbackWrapper(children) : children
};

Conditional.propTypes = {
  condition: PropTypes.any,
  fallbackWrapper: PropTypes.func,
  wrapper: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
