import { graphql, useStaticQuery } from 'gatsby';

import { getProperty } from 'utils';

import { useFlatten } from './use-flatten';

export const usePersons = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryPersonsContents';

	return useFlatten(getProperty(results, key) || {});
};

export const usePersonById = (value) =>
	usePersons().find((person) => {
		const personId = getProperty(person, 'id');
		return personId === value;
	});

export const usePersonsByDepartmentId = (value) =>
	usePersons().filter((person) => {
		const departmentId = getProperty(person, 'data.departments.0.id');
		return departmentId === value;
	});

const query = graphql`
	query usePersonsQuery {
		squidex {
			queryPersonsContents(top: 999) {
				id
				data {
					name {
						de
					}
					role {
						de
					}
					phone {
						iv
					}
					email {
						iv
					}
					departments {
						iv {
							id
						}
					}
					image {
						iv {
							localFile {
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										width: 175
										height: 225
										quality: 100
										transformOptions: { cropFocus: CENTER }
									)
								}
							}
							...SquidexImageLocalFileProps
						}
					}
				}
			}
		}
	}
`;
