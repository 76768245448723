import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './card.module.scss';

export const Card = ({ size, header, footer, children }) => {
  const wrapperClassName = useMemo(() => [styles.wrapper, styles[size]].join(' '), [
    size,
  ]);

  return (
    <div className={wrapperClassName}>
      {header && <div className={styles.header}>{header}</div>}
      <div className={styles.content}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};

Card.defaultProps = {
  size: 'md',
};

Card.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};
