import React, {
  createContext,
  forwardRef,
  useMemo,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Map } from 'leaflet';

import { MAP_CONTAINER_DEFAULT_OPTIONS } from './options';

import styles from './map-container.module.scss';

const MAP_CONTEXT_DEFAULT = {
  map: null,
};

export const MapContext = createContext(MAP_CONTEXT_DEFAULT);

export const MapContainer = forwardRef(
  ({ children, options, className, onInitialized }, ref) => {
    const [map, setMap] = useState(null);
    const wrapperRef = useRef(null);

    const wrapperclassName = useMemo(() => [styles.wrapper, className].join(' '), [
      className,
    ]);

    useEffect(() => {
      const { current: element } = wrapperRef;
      const map = new Map(element, options);
      setMap(map);

      const teardown  = onInitialized(map);

      return () => {
        if (typeof teardown === 'function') {
          teardown();
        }

        map.remove();
        setMap(null);
      };
    }, [options, onInitialized]);

    useImperativeHandle(ref, () => map, [map]);

    return (
      <MapContext.Provider value={map}>
        <div ref={wrapperRef} className={wrapperclassName}>
          {children}
        </div>
      </MapContext.Provider>
    );
  }
);

MapContainer.defaultProps = {
  className: '',
  options: MAP_CONTAINER_DEFAULT_OPTIONS,
  onInitialized: (/* map */) => {},
};

MapContainer.propTypes = {
  className: PropTypes.string,
  options: PropTypes.shape({
    zoomControl: PropTypes.bool,
    attributionControl: PropTypes.bool,
    boxZoom: PropTypes.bool,
    doubleClickZoom: PropTypes.bool,
    dragging: PropTypes.bool,
    keyboard: PropTypes.bool,
    prefix: PropTypes.bool,
    scrollWheelZoom: PropTypes.oneOf(['center', false, true]),
    tap: PropTypes.bool,
    touchZoom: PropTypes.oneOf(['center', false, true]),
    zoomSnap: PropTypes.number,
    zoomDelta: PropTypes.number,
    maxBoundsViscosity: PropTypes.number,
  }),
  onInitialized: PropTypes.func,
};
