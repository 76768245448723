import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticHomepage = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryHomepageContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useHomepageQuery {
		squidex {
			queryHomepageContents {
				data {
					seo {
						de {
							title
							description
						}
					}

					infobox {
						de {
							title
						}
					}

					teaserImage {
						iv {
							localFile {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH, quality: 100)
								}
							}
							...SquidexImageLocalFileProps
						}
					}

					blockIntroLink {
						iv {
							data {
								path {
									de
								}
							}
						}
					}

					blockDevelopLink {
						iv {
							data {
								path {
									de
								}
							}
						}
					}

					blockDevelopImage {
						iv {
							localFile {
								childImageSharp {
									gatsbyImageData(layout: CONSTRAINED, width: 768, quality: 100)
								}
							}
							...SquidexImageLocalFileProps
						}
					}

					blockConstructLink {
						iv {
							data {
								path {
									de
								}
							}
						}
					}

					blockConstructImage {
						iv {
							localFile {
								childImageSharp {
									gatsbyImageData(layout: CONSTRAINED, width: 768, quality: 100)
								}
							}
							...SquidexImageLocalFileProps
						}
					}

					develop {
						de {
							title
							text
						}
					}

					projects {
						de {
							title
							text
						}
					}

					about {
						de {
							text
							link {
								data {
									path {
										de
									}
								}
							}
							linkText
						}
					}
				}
			}
		}
	}
`;
