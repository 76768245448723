import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticShared = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.querySharedContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useSharedQuery {
		squidex {
			querySharedContents {
				id
				data {
					meta {
						de {
							companyName
							page
						}
					}

					cookies {
						de {
							text
							accept
							privacy
						}
					}

					notFound {
						de {
							title
							text
							backToHome
						}
					}

					portal {
						de {
							url
							text
						}
					}
					search {
						de {
							placeholder
							noResults
						}
					}
					map {
						de {
							resetView
							resetFilter
							noLocations
							searchPlaceholder
						}
					}
					buttons {
						de {
							goToProject
							showMore
							backToOverview
						}
					}
					projects {
						de {
							pathPrefix
							noProjects
							noProjectsInCategory
						}
					}
					articles {
						de {
							title
							noArticles
						}
					}
				}
			}
		}
	}
`;
