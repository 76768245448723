import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticFacts = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryFactsContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useFactsQuery {
		squidex {
			queryFactsContents {
				id
				data {
					employees {
						iv
					}
					communities {
						iv
					}
					residences {
						iv
					}
					objects {
						iv
					}
					rentFacilities {
						iv
					}
					hirePurchaseFacilities {
						iv
					}
					propertyFacilities {
						iv
					}
				}
			}
		}
	}
`;
