import { graphql, useStaticQuery } from 'gatsby';

import { getProperty } from 'utils';

import { useFlatten } from './use-flatten';

export const useInfos = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryInfosContents';

	return useFlatten(getProperty(results, key) || {});
};

export const useInfoById = (value) =>
	useInfos().find((info) => {
		const infoId = getProperty(info, 'id');
		return infoId === value;
	});

const query = graphql`
	query useInfosQuery {
		squidex {
			queryInfosContents(top: 999, orderby: "data/rank/iv") {
				id
				data {
					name {
						de
					}
					text {
						de
					}
					files {
						de {
							name
							file {
								url
							}
						}
					}
				}
			}
		}
	}
`;
