import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticDevelop = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryDevelopContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useDevelopQuery {
		squidex {
			queryDevelopContents {
				id
				data {
					seo {
						de {
							title
							description
						}
					}

					backgroundImageFocusAreas {
						iv {
							localFile {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH, quality: 100)
								}
							}
							...SquidexImageLocalFileProps
						}
					}

					map {
						de {
							title
							text
							filterTitle
						}
					}
				}
			}
		}
	}
`;
