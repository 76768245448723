import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticFeaturedProjects = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryFeaturedProjectsContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useFeaturedProjectsQuery {
		squidex {
			queryFeaturedProjectsContents {
				id
				data {
					list {
						iv {
							project {
								id
							}
						}
					}
				}
			}
		}
	}
`;
