import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { sanitize } from 'isomorphic-dompurify';
import { parse } from 'marked';

import { HTML } from './html';

export const Markdown = ({ content, ...props }) => {
	const html = useMemo(() => sanitize(parse(content)), [content]);

	return <HTML content={html} {...props} />;
};

Markdown.propTypes = {
	content: PropTypes.node.isRequired,
};
