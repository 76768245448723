import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './hamburger.module.scss';

export const Hamburger = ({ isActive, className, width, height }) => {
  const wrapperclassName = useMemo(
    () => [styles.wrapper, isActive ? styles.active : ''].join(' '),
    [isActive]
  );

  const wrapperStyles = useMemo(() => ({ width, height }), [width, height]);

  return (
    <svg viewBox="0 0 50 50" className={wrapperclassName} style={wrapperStyles}>
      <g className={styles.lines}>
        <line x1="5" x2="45" y1="10" y2="10" className={styles.line1} />
        <line x1="5" x2="45" y1="25" y2="25" className={styles.line2} />
        <line x1="5" x2="45" y1="40" y2="40" className={styles.line3} />
      </g>
      <circle className={styles.circle} cx="25" cy="25" r="20" />
    </svg>
  );
};

Hamburger.defaultProps = {
  isActive: false,
  height: '2.5rem',
  width: '2.5rem',
};

Hamburger.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  isActive: PropTypes.bool,
};
