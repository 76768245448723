import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticHours = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryHoursContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useHoursQuery {
		squidex {
			queryHoursContents {
				id
				data {
					items {
						de {
							label
							value
						}
					}
				}
			}
		}
	}
`;
