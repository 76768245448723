import React from 'react';
import PropTypes from 'prop-types';

export const HTML = ({ content, ...props }) => (
  <div
    {...props}
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
);

HTML.propTypes = {
  content: PropTypes.node.isRequired,
};
