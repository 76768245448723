import React, { createContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

export const THEME_DEFAULT = 'DEFAULT';
export const THEME_MINIMAL = 'MINIMAL';

const THEMES = {
  [THEME_DEFAULT]: true,
  [THEME_MINIMAL]: true,
};

const validateTheme = (theme) => {
  if (theme in THEMES) {
    return theme;
  }

  return THEME_DEFAULT;
};

export const ThemeProvider = ({ initialTheme, ...props }) => {
  const [theme, setTheme] = useState(validateTheme(initialTheme));
  const validateSetter = useCallback((theme) => {
    theme = validateTheme(theme);
    setTheme(theme);
  }, []);

  const value = useMemo(
    () => ({
      theme,
      setTheme: validateSetter,
    }),
    [theme, validateSetter]
  );

  return <ThemeContext.Provider value={value} {...props} />;
};

ThemeProvider.defaultProps = {
  initialTheme: THEME_DEFAULT,
};

ThemeProvider.propTypes = {
  initialTheme: PropTypes.oneOf(Object.keys(THEMES)),
};

export const ThemeContext = createContext();
