import { isClient } from './misc';

export const getCookie = (key) => {
  const cookies = getAllCookies();
  return cookies[key] || null;
};

export const setCookie = (key, value, expiresInDays, domain, path, secure) => {
  if (!isClient) {
    return;
  }

  let cookie = `${encode_(key)}=${encode_(value)}`;

  let expires;
  if (typeof expiresInDays === 'number') {
    const timeNowInMs = new Date().getTime();
    const timeDayInMs = expiresInDays * 24 * 60 * 60 * 1000;
    expires = new Date(timeNowInMs + timeDayInMs).toUTCString();
  }

  if (expires) {
    cookie = `${cookie};expires=${expires}`;
  }

  if (domain) {
    cookie = `${cookie};domain=${domain}`;
  }

  if (path) {
    cookie = `${cookie};path=${path}`;
  }

  if (secure) {
    cookie = `${cookie};secure`;
  }

  document.cookie = cookie;
};

export const removeCookie = (key) => {
  setCookie(key, '', -1);
};

export const getAllCookies = () => {
  if (!isClient) {
    return {};
  }

  const items = {};
  const cookies = document.cookie.split('; ');
  if (cookies.length === 1 && cookies[0] === '') {
    return items;
  }

  for (let i = 0; i < cookies.length; i++) {
    const [key, value] = cookies[i].split('=');
    items[decode_(key)] = decode_(value);
  }

  return items;
};

export const removeAllCookies = () => {
  for (let key in getAllCookies()) {
    removeCookie(key);
  }
};

const encode_ = (value) => {
  try {
    return encodeURIComponent(JSON.stringify(value));
  } catch (e) {
    return null;
  }
};

const decode_ = (value) => {
  try {
    return JSON.parse(decodeURIComponent(value));
  } catch (e) {
    return null;
  }
};
