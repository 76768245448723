import React, { createContext, useMemo, useState, useEffect, useRef } from "react";

import { getScrollOffsetY } from "utils";

export const ScrollProvider = ({ children }) => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const scrollYOffset = getScrollOffsetY();
  const foreLastScrollYPos = useRef(scrollYOffset);
  const prevScrollY = useRef(scrollYOffset);
  const recursionDetected = useRef(false);
  const isTicking = useRef(false);

  useEffect(() => {
    const handleScroll = (/* event */) => {
      if (recursionDetected.current !== false) {
        return;
      }

      // note: for delta scroll pos should be larger 0 to avoid taking browser bouncing effects
      // into account such as the one used in iOS when scrolling up at the top of the page!
      const lastScrollY = getScrollOffsetY();
      const scrollDelta = lastScrollY > 0 ? lastScrollY - prevScrollY.current : 0;

      if (!isTicking.current) {
        requestAnimationFrame(() => {
          // note: this happens if setting a fixed header gets follwed by a disappearing
          // scrollbar as page gets too small, but this would force an infinite loop in
          // browser, as the scroll position then jumps to 0 - stop updates in this case
          recursionDetected.current = lastScrollY === 0 && foreLastScrollYPos.current === 0;
          foreLastScrollYPos.current = prevScrollY.current;
          prevScrollY.current = lastScrollY;

          setIsScrollingDown(scrollDelta > 0);
          setIsScrollingUp(scrollDelta < 0);
          setScrollY(lastScrollY);

          isTicking.current = false;
        });

        isTicking.current = true;
      }
    };

    document.addEventListener("scroll", handleScroll, false);

    return () => {
      document.removeEventListener("scroll", handleScroll, false);
    };
  }, []);

  useEffect(() => {
    const handleResize = (/* event */) => {
      recursionDetected.current = false;
    };

    document.addEventListener("resize", handleResize, false);

    return () => {
      document.removeEventListener("resize", handleResize, false);
    };
  }, []);

  const value = useMemo(
    () => ({
      isScrollingDown,
      isScrollingUp,
      scrollY,
    }),
    [isScrollingDown, isScrollingUp, scrollY]
  );

  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};

export const ScrollContext = createContext();
