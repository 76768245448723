import { useCallback } from 'react';

import { isObject } from 'utils';
import { useLocale } from '../use-locale';

const INVARIANT_KEY = 'iv';
const cache = new Map();

export const useFlatten = (data, postProcessData) => {
  postProcessData =
    typeof postProcessData !== 'function' ? (data) => data : postProcessData;

  const { locale } = useLocale();
  const reviver = useCallback(
    (key, value) => {
      if (!isObject(value)) {
        return value;
      }

      if (typeof value[INVARIANT_KEY] !== 'undefined') {
        return value[INVARIANT_KEY];
      } else if (typeof value[locale] !== 'undefined') {
        return value[locale];
      } else {
        return value;
      }
    },
    [locale]
  );

  if (!cache.has(data)) {
    const json = JSON.stringify(data);
    const flat = JSON.parse(json, reviver);
    cache.set(data, postProcessData(flat));
  }

  return cache.get(data);
};
