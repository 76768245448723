import { forwardRef, useState, useEffect, useContext, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { GeoJSON } from 'leaflet';

import { MapContext } from './map-container';
import { MAP_GEO_JSON_DEFAULT_OPTIONS } from './options';

// export const MAP_GEO_JSON_DEFAULT_OPTIONS = {
//   style: {
//     fillColor: 'white',
//     color: 'white',
//     fillOpacity: 1,
//     opacity: 1,
//     weight: 1,
//   },
//   interactive: true,
// };

export const MapGeoJson = forwardRef(
  ({ children, data, options, onInitialized }, ref) => {
    const [geoJson, setGeoJson] = useState(null);
    const map = useContext(MapContext);

    useImperativeHandle(ref, () => geoJson, [geoJson]);

    useEffect(() => {
      if (map === null) {
        return;
      }

      const geoJson = new GeoJSON(data, options);
      setGeoJson(geoJson);

      map.addLayer(geoJson);
      map.fitBounds(geoJson.getBounds());

      map.invalidateSize();

      const teardown = onInitialized(map, geoJson);

      return () => {
        if (typeof teardown === 'function') {
          teardown();
        }

        geoJson.remove();
        setGeoJson(null);
      };
    }, [map, data, options, onInitialized]);

    return children;
  }
);

MapGeoJson.defaultProps = {
  children: null,
  options: MAP_GEO_JSON_DEFAULT_OPTIONS,
  onInitialized: (/* map, geoJson */) => {},
};

MapGeoJson.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.shape({
    style: PropTypes.shape({
      fillColor: PropTypes.string,
      fillOpacity: PropTypes.number,
      color: PropTypes.string,
      weight: PropTypes.number,
      opacity: PropTypes.number,
    }),
    interactive: PropTypes.bool,
  }),
  onInitialized: PropTypes.func,
};
