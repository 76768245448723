import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticContactData = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryContactDataContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useContactDataQuery {
		squidex {
			queryContactDataContents {
				id
				data {
					address {
						iv {
							line
							isLegal
						}
					}
					email {
						iv
					}
					phone {
						iv
					}
					condominium {
						iv
					}
					branchOffices {
						de {
							name
							phone
							email
							addressLine1
							addressLine2
						}
					}
					position {
						iv
					}
					legalForm {
						de
					}
					lineOfBusiness {
						de
					}
					chambers {
						de
					}
					regulators {
						de
					}
					tradeRegulations {
						de
					}
					headquarters {
						de
					}
					corporateRegisterCourt {
						de
					}
					corporateRegisterNr {
						de
					}
					valueAddedTaxNr {
						de
					}
					dataProcessingRegisterNr {
						de
					}
				}
			}
		}
	}
`;
