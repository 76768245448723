import React from 'react';
import { Link } from 'gatsby';

import { LogoOutlineBigIcon } from 'icons';

import { toPhoneUri, toEmailUri, getProperty } from 'utils';
import {
	useStaticGtc,
	useStaticFooter,
	useStaticContactData,
	usePagesByMenu,
} from 'hooks';
import { Text, Title, ObfuscateLink, ObfuscateText, ContainerToMD } from 'components';

import styles from './contact.module.scss';

export const Contact = () => {
	const staticGtcData = useStaticGtc();
	const staticFooter = useStaticFooter();
	const staticContactData = useStaticContactData();
	const pages = usePagesByMenu('footer');

	const { title } = getProperty(staticFooter, 'data.contact.0');

	const phoneText = getProperty(staticContactData, 'data.phone');
	const emailText = getProperty(staticContactData, 'data.email');
	const address = getProperty(staticContactData, 'data.address') || [];

	const phoneUri = toPhoneUri(phoneText);
	const emailUri = toEmailUri(emailText);

	const branchOffices = getProperty(staticContactData, 'data.branchOffices') || [];

	const avbText = getProperty(staticGtcData, 'data.header.0.title');
	const avbUri = getProperty(staticGtcData, 'data.file.0.url');
	console.log(staticGtcData);

	return (
		<div className={styles.wrapper}>
			<ContainerToMD>
				<Title size="sm" color="white" type="special" wrapper="h3">
					{title}
				</Title>
				<div className={styles.header}>
					<ObfuscateLink href={phoneUri} className={styles.uri}>
						<Text size="md" type="special" color="white">
							T: <ObfuscateText text={phoneText} />
						</Text>
					</ObfuscateLink>
					<ObfuscateLink href={emailUri} className={styles.uri}>
						<Text size="md" type="special" color="white">
							<ObfuscateText text={emailText} />
						</Text>
					</ObfuscateLink>
				</div>

				<div className={styles.content}>
					<address className={styles.address}>
						{address
							.filter(({ isLegal }) => !isLegal)
							.map(({ line }, index) => (
								<Text size="xl" key={index} weight="bold" color="white">
									{line}
								</Text>
							))}
					</address>

					<div className={styles.branches}>
						{branchOffices.map((branchOffice, index) => {
							const name = getProperty(branchOffice, 'name');
							const phoneText = getProperty(branchOffice, 'phone');
							const emailText = getProperty(branchOffice, 'email');
							const addressLine1 = getProperty(branchOffice, 'addressLine1');
							const addressLine2 = getProperty(branchOffice, 'addressLine2');

							const phoneUri = toPhoneUri(phoneText);
							const emailUri = toEmailUri(emailText);

							return (
								<div key={index} className={styles.branch}>
									<Text size="md" type="special" color="white">
										{name}
									</Text>
									<ObfuscateLink href={phoneUri} className={styles.uri}>
										<Text size="md" type="special" color="white">
											T: <ObfuscateText text={phoneText} />
										</Text>
									</ObfuscateLink>
									<ObfuscateLink href={emailUri} className={styles.uri}>
										<Text size="md" type="special" color="white">
											<ObfuscateText text={emailText} />
										</Text>
									</ObfuscateLink>
									<address>
										<Text size="md" type="special" color="white">
											{addressLine1}
										</Text>
										<Text size="md" type="special" color="white">
											{addressLine2}
										</Text>
									</address>
								</div>
							);
						})}
					</div>

					<nav className={styles.nav}>
						{pages.map((page) => {
							const id = getProperty(page, 'id');
							const path = getProperty(page, 'data.path');
							const title = getProperty(page, 'data.title');

							return (
								<Link to={path} key={id} className={styles.navItem}>
									<Text size="xs" type="special" color="white">
										{title}
									</Text>
								</Link>
							);
						})}
						<a
							href={avbUri}
							target="_blank"
							rel="noreferrer"
							download={avbText}
							className={styles.navItem}>
							<Text size="xs" type="special" color="white">
								{avbText}
							</Text>
						</a>
					</nav>
					<div className={styles.logo}>
						<LogoOutlineBigIcon className={styles.logoSvg} />
					</div>
				</div>
			</ContainerToMD>
		</div>
	);
};
