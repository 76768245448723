import React, { useCallback, useMemo, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components';
import { ArrowUpIcon } from 'icons';
import { ArrowDownIcon } from 'icons';

import { AccordionContext } from './accordion';

import styles from './expandable.module.scss';

export const Expandable = ({ children, id, label, isOpened, autoClose, onClick }) => {
  const { openedId, toggleOpenedId } = useContext(AccordionContext);

  const [currentIsOpened, setCurrentIsOpened] = useState(isOpened);

  const wrapperclassName = useMemo(
    () => [styles.wrapper, currentIsOpened ? styles.active : ''].join(' '),
    [currentIsOpened]
  );

  const clickHandler = useCallback(() => {
    setCurrentIsOpened((oldIsOpened) => {
      const newIsOpened = !oldIsOpened;
      onClick(newIsOpened);
      return newIsOpened;
    });

    toggleOpenedId(id);
  }, [onClick, toggleOpenedId, id]);

  useEffect(() => {
    setCurrentIsOpened(isOpened);
  }, [isOpened]);

  useEffect(() => {
    if (!autoClose) {
      return;
    }
    setCurrentIsOpened(openedId === id);
  }, [autoClose, openedId, id]);

  return (
    <div className={wrapperclassName}>
      <button className={styles.button} onClick={clickHandler}>
        <div className={styles.icon}>
          {currentIsOpened ? (
            <ArrowUpIcon className={styles.svg} />
          ) : (
            <ArrowDownIcon className={styles.svg} />
          )}
        </div>
        <div className={styles.label}>
          <Text size="lg" weight="bold">
            {label}
          </Text>
        </div>
      </button>
      {currentIsOpened && <div className={styles.content}>{children}</div>}
    </div>
  );
};

Expandable.defaultProps = {
  id: null,
  label: null,
  isOpened: false,
  autoClose: false,
  onClick: () => {},
};

Expandable.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isOpened: PropTypes.bool,
  autoClose: PropTypes.bool,
  onClick: PropTypes.func,
};
