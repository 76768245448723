import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'components';

import { Logo } from './header/logo';
import { Menu } from './header/menu';
import { Nav } from './header/nav';

import styles from './header.module.scss';

//
// HEADER
//
export const Header = ({ showMenu, isHidden, isFixed, onToggle }) => {
  const wrapperclassName = useMemo(
    () =>
      [styles.wrapper, isFixed ? styles.fixed : '', isHidden ? styles.hidden : ''].join(
        ' '
      ),
    [isFixed, isHidden]
  );

  return (
    <header className={wrapperclassName}>
      <Container>
        <div className={styles.container}>
          <Menu isOpen={showMenu} onToggle={onToggle} />
          <Nav />
          <Logo />
        </div>
      </Container>
    </header>
  );
};

Header.defaultProps = {
  isFixed: false,
  showMenu: false,
  isHidden: false,
  onToggle: () => {},
};

Header.propTypes = {
  isFixed: PropTypes.bool.isRequired,
  showMenu: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
};
