import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './section.module.scss';

export const Section = ({
  className,
  color,
  size,
  noPaddingTop,
  noPaddingBottom,
  contentVisibility,
  backgroundImageUrl,
  ...props
}) => {
  const wrapperClassName = useMemo(
    () =>
      [
        styles.wrapper,
        styles[color],
        styles[size],
        noPaddingTop ? styles.noPaddingTop : '',
        noPaddingBottom ? styles.noPaddingBottom : '',
        contentVisibility ? styles.contentVisibility : '',
      ].join(' '),
    [color, size, noPaddingTop, noPaddingBottom, contentVisibility]
  );

  return <section className={wrapperClassName} {...props} />;
};

Section.defaultProps = {
  contentVisibility: false,
  noPaddingBottom: false,
  noPaddingTop: false,
  color: 'inherit',
  size: 'md',
};

Section.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'grey',
    'white',
    'black',
    'inherit',
    'transparent',
  ]),
  noPaddingTop: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  contentVisibility: PropTypes.bool,
};
