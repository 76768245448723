import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticCompany = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryCompanyContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useCompanyQuery {
		squidex {
			queryCompanyContents {
				id
				data {
					seo {
						de {
							title
							description
						}
					}

					header {
						de {
							title
						}
					}

					content {
						de {
							title
							text
						}
					}

					factsContent {
						de {
							backgroundImage {
								localFile {
									childImageSharp {
										gatsbyImageData(layout: FULL_WIDTH, quality: 100)
									}
								}
								...SquidexImageLocalFileProps
							}
						}
					}

					factsItems {
						de {
							key
							textBefore
							textAfter
						}
					}

					departments {
						de {
							text
						}
					}

					historyContent {
						de {
							title
						}
					}

					historyItems {
						de {
							date
							text
							image {
								localFile {
									childImageSharp {
										gatsbyImageData(layout: CONSTRAINED, width: 500, quality: 100)
									}
								}
								...SquidexImageLocalFileProps
							}
						}
					}
				}
			}
		}
	}
`;
