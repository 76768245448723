import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './title.module.scss';

export const Title = ({
  wrapper: Wrapper,
  noMargin,
  color,
  size,
  type,
  children,
  className,
  truncate,
  ...props
}) => {
  size = useMemo(() => {
    if (typeof size !== 'undefined') {
      return size;
    }

    switch (Wrapper) {
      case 'h1':
        return 'xxl';
      case 'h2':
        return 'xl';
      case 'h3':
        return 'lg';
      case 'h4':
        return 'md';
      case 'h5':
        return 'sm';
      case 'h6':
        return 'xs';
      default:
        throw new Error(`Unsupported wrapper: ${Wrapper}`);
    }
  }, [size, Wrapper]);

  const wrapperClassName = useMemo(
    () =>
      [
        styles.title,
        styles[type],
        styles[size],
        styles[color],
        styles[Wrapper],
        noMargin ? styles.noMargin : '',
      ].join(' '),
    [type, size, color, Wrapper, noMargin]
  );

  const content = useMemo(
    () =>
      typeof children === 'string' && typeof truncate === 'number'
        ? children.length > truncate
          ? `${children.slice(0, truncate)}...`
          : children
        : children,
    [children, truncate]
  );

  return (
    <Wrapper className={wrapperClassName} {...props}>
      {content}
    </Wrapper>
  );
};

Title.defaultProps = {
  noMargin: false,
  type: 'default',
  color: 'inherit',
  wrapper: 'h2',
};

Title.propTypes = {
  noMargin: PropTypes.bool,
  type: PropTypes.oneOf(['default', 'special']),
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'black', 'warning', 'inherit']),
  wrapper: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  truncate: PropTypes.number,
};
