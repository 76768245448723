import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

export const Truncate = ({ children, maxChars }) => {
  const processed = useMemo(
    () =>
      typeof children === 'string'
        ? children.length > maxChars
          ? `${children.slice(0, maxChars)}...`
          : children
        : children,
    [children, maxChars]
  );

  return <Fragment>{processed}</Fragment>;
};

Truncate.defaultProps = {
  maxChars: 100,
};

Truncate.propTypes = {
  maxChars: PropTypes.number.isRequired,
};
