import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMatchMedia } from 'hooks';
import { XS } from 'utils';

import { Text } from 'components';

import { ArrowRightIcon } from 'icons';

import styles from './arrow-link.module.scss';

const MEDIA_QUERIES = [`(min-width:${XS}px)`];

export const ArrowLink = ({ wrapper: Wrapper, color, size, children, ...props }) => {
  const [isXS] = useMatchMedia(MEDIA_QUERIES);

  const containerclassName = useMemo(() => [styles.container, styles[size]].join(' '), [
    size,
  ]);
  const [lg, sm] = useMemo(() => {
    switch (size) {
      case 'lg':
        return ['xxl', 'xl'];
      default:
        return ['xl', 'lg'];
    }
  }, [size]);

  return (
    <Wrapper {...props}>
      <Text size={isXS ? lg : sm} weight="bold" color={color}>
        <div className={containerclassName}>
          <div className={styles.icon}>
            <ArrowRightIcon className={styles.svg} />
          </div>
          <div className={styles.text}>{children}</div>
        </div>
      </Text>
    </Wrapper>
  );
};

ArrowLink.defaultProps = {
  color: 'primary',
  wrapper: 'div',
  size: 'md',
};

ArrowLink.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'black', 'white']),
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  wrapper: PropTypes.elementType,
};
