import React, { useMemo, useCallback, useState, useEffect } from 'react';

import { getProperty } from 'utils';
import { THEME_DEFAULT } from 'contexts';
import { useKeyPress, useScroll, useTheme } from 'hooks';

import { Header } from './header';
import { Footer } from './footer';
import { Quickinfo } from './quickinfo';
import { Consent } from './consent';

import styles from './layout.module.scss';

//
// LAYOUT
//
export const Layout = ({ children, pageContext }) => {
  const path = getProperty(pageContext, 'data.path');

  const [hideHeader, setHideHeader] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const { scrollY, isScrollingDown } = useScroll();
  const isEscapePressed = useKeyPress('Escape');
  const { theme } = useTheme();

  const hasScrolled = useMemo(() => scrollY > 0, [scrollY]);
  const showFooterBackgroundImage = useMemo(() => theme === THEME_DEFAULT, [theme]);

  const onToggle = useCallback(() => setShowMenu(!showMenu), [showMenu]);

  useEffect(() => {
    setHideHeader(isScrollingDown);
  }, [isScrollingDown]);

  useEffect(() => {
    setIsFixed(hasScrolled);
  }, [hasScrolled]);

  useEffect(() => {
    if (isEscapePressed) {
      setShowMenu(false);
    }
  }, [isEscapePressed]);

  useEffect(() => {
    setShowMenu(false);
  }, [path]);

  return (
    <div className={styles.wrapper}>
      <Header
        isFixed={isFixed}
        onToggle={onToggle}
        showMenu={showMenu}
        isHidden={hideHeader}
      />
      <main className={styles.main}>{children}</main>
      <Footer withBackgroundImage={showFooterBackgroundImage} />
      <Quickinfo />
      <Consent />
    </div>
  );
};
