import React, { Children, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMatchMedia } from 'hooks';

import styles from './masonry-grid.module.scss';

export const MasonryGrid = ({ children, gap, breakpoints }) => {
  const mediaQueries = useMemo(
    () => breakpoints.map(({ minWidth }) => `(min-width:${minWidth})`),
    [breakpoints]
  );
  const items = useMemo(() => Children.toArray(children), [children]);
  const matches = useMatchMedia(mediaQueries);

  const columnCount = useMemo(() => {
    let i = 0;
    let columns = 1;

    while (i < matches.length) {
      const match = matches[i];
      if (!match) {
        break;
      }

      const breakpoint = breakpoints[i];
      if (!breakpoint) {
        break;
      }

      columns = breakpoint.columns;
      i++;
    }

    return columns;
  }, [breakpoints, matches]);

  const columns = useMemo(() => {
    const columns = new Array(columnCount);

    for (let i = 0; i < items.length; i++) {
      const index = i % columnCount;
      if (!columns[index]) {
        columns[index] = [];
      }

      columns[index].push(items[i]);
    }

    return columns;
  }, [items, columnCount]);

  const columnStyle = useMemo(
    () => ({
      width: `${100 / columns.length}%`,
      borderRight: `${gap} solid transparent`,
    }),
    [gap, columns]
  );

  const itemStyle = useMemo(
    () => ({
      marginBottom: gap,
    }),
    [gap]
  );

  return (
    <div className={styles.wrapper}>
      {columns.map((items, index) => (
        <div key={index} style={columnStyle} className={styles.column}>
          {Children.map(items, (item, index) => (
            <div key={index} style={itemStyle} className={styles.item}>
              {item}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

MasonryGrid.defaultProps = {
  gap: '1rem',
  breakpoints: [
    { minWidth: '480px', columns: 2 },
    { minWidth: '960px', columns: 3 },
    { minWidth: '1280px', columns: 4 },
  ],
};

MasonryGrid.propTypes = {
  gap: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  breakpoints: PropTypes.arrayOf(
    PropTypes.shape({
      minWidth: PropTypes.string,
      columns: PropTypes.number,
    })
  ),
};
