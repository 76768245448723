import React, { Fragment, useState, useMemo, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';

import { Button, Title, Text, ArrowLink } from 'components';
import { XS, toPhoneUri, toEmailUri, getProperty } from 'utils';
import {
  useStaticContactData,
  useStaticQuickinfo,
  useScroll,
  useKeyPress,
  useMatchMedia,
} from 'hooks';

import { PhoneIcon, CloseIcon, MailIcon } from 'icons';

import styles from './quickinfo.module.scss';

const MEDIA_QUERIES = [`(min-width:${XS}px)`];

export const Quickinfo = () => {
  const staticQuickinfo = useStaticQuickinfo();
  const staticContactData = useStaticContactData();

  const { scrollY } = useScroll();
  const [isXS] = useMatchMedia(MEDIA_QUERIES);
  const isEscapePressed = useKeyPress('Escape');
  const [hideButtons, setHideButtons] = useState(false);
  const [isMailVisible, setIsMailVisible] = useState(false);
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);

  const buttonsWrapperclassName = useMemo(
    () => [styles.buttons, hideButtons ? styles.buttonsHidden : ''].join(' '),
    [hideButtons]
  );

  const overlayMailWrapperclassName = useMemo(
    () => [styles.overlayMail, isMailVisible ? styles.overlayVisible : ''].join(' '),
    [isMailVisible]
  );
  const overlayPhoneWrapperclassName = useMemo(
    () => [styles.overlayPhone, isPhoneVisible ? styles.overlayVisible : ''].join(' '),
    [isPhoneVisible]
  );

  const clickPhoneHandler = useCallback(() => {
    setIsPhoneVisible(!isPhoneVisible);
    setIsMailVisible(false);
  }, [isPhoneVisible]);

  const clickMailHandler = useCallback(() => {
    setIsMailVisible(!isMailVisible);
    setIsPhoneVisible(false);
  }, [isMailVisible]);

  const { title: titleMail, text: textMail, linkText: linkTextMail, link = [] } =
    getProperty(staticQuickinfo, 'data.mail.0') || {};
  const linkPathMail = getProperty(link, '0.data.path');

  const { title: titlePhone, text: textPhone } =
    getProperty(staticQuickinfo, 'data.phone.0') || {};
  const { title: titleCondominium, text: textCondominium } =
    getProperty(staticQuickinfo, 'data.condominium.0') || {};

  const phoneText = getProperty(staticContactData, 'data.phone');
  const emailText = getProperty(staticContactData, 'data.email');
  const condominiumText = getProperty(staticContactData, 'data.condominium');

  const emailUri = toEmailUri(emailText);
  const phoneUri = toPhoneUri(phoneText);
  const condominiumUri = toPhoneUri(condominiumText);

  useEffect(() => {
    if (isEscapePressed) {
      setIsMailVisible(false);
      setIsPhoneVisible(false);
    }
  }, [isEscapePressed]);

  useEffect(() => {
    if (!isXS) {
      setIsMailVisible(false);
      setIsPhoneVisible(false);
      setHideButtons(scrollY > 0);
    }
  }, [scrollY, isXS]);

  return (
    <Fragment>
      <div className={buttonsWrapperclassName}>
        <div className={styles.buttonMail}>
          <Button
            size="lg"
            color="white"
            type="rounded"
            hover={false}
            onClick={clickMailHandler}>
            <MailIcon className={styles.buttonMailSvg} />
          </Button>
        </div>
        <div className={styles.buttonPhone}>
          <Button size="lg" type="rounded" hover={false} onClick={clickPhoneHandler}>
            <PhoneIcon className={styles.buttonPhoneSvg} />
          </Button>
        </div>
      </div>

      <div className={overlayMailWrapperclassName}>
        <div className={styles.overlayIcon}>
          <MailIcon className={styles.overlayIconSvg} />
        </div>
        <div className={styles.overlayCloseButton}>
          <Button size="sm" type="outline" hover={false} onClick={clickMailHandler}>
            <CloseIcon className={styles.overlayCloseSvg} />
          </Button>
        </div>

        <div className={styles.overlayContent}>
          <Title size="md" type="special" color="primary" wrapper="h4">
            {titleMail}
          </Title>
          <Text size="md">{textMail}</Text>

          <a href={emailUri} onClick={clickMailHandler} className={styles.overlayUri}>
            <Text size="md" type="special" color="primary">
              {emailText}
            </Text>
          </a>
        </div>

        <ArrowLink wrapper={Link} to={linkPathMail} onClick={clickMailHandler}>
          {linkTextMail}
        </ArrowLink>
      </div>

      <div className={overlayPhoneWrapperclassName}>
        <div className={styles.overlayIcon}>
          <PhoneIcon className={styles.overlayIconSvg} />
        </div>
        <div className={styles.overlayCloseButton}>
          <Button
            size="sm"
            type="outline"
            color="white"
            hover={false}
            onClick={clickPhoneHandler}>
            <CloseIcon className={styles.overlayCloseSvg} />
          </Button>
        </div>

        {/* <div className={styles.overlayContent}> */}
          <Title size="md" type="special" color="white" wrapper="h4">
            {titlePhone}
          </Title>
          <Text size="md">{textPhone}</Text>

          <a href={phoneUri} onClick={clickPhoneHandler} className={styles.overlayUri}>
            <Text size="md" type="special" color="white">
              {phoneText}
            </Text>
          </a>
        {/* </div>
        <Title size="md" type="special" color="white" wrapper="h4">
          {titleCondominium}
        </Title>
        <Text size="md">{textCondominium}</Text>

        <a href={condominiumUri} onClick={clickPhoneHandler} className={styles.overlayUri}>
          <Text size="md" type="special" color="white">
            {condominiumText}
          </Text>
        </a> */}
      </div>
    </Fragment>
  );
};
