import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useKeyPress } from 'hooks';
import { Modal, Button } from 'components';
import { getProperty, arrayToObject, debounce } from 'utils';
import { CloseIcon, ArrowLeftIcon, ArrowRightIcon } from 'icons';

import styles from './image-gallery.module.scss';

export const ImageGallery = ({ children, thumbs, images }) => {
  const [lightboxImage, setLightboxImage] = useState(null);
  const isArrowRightKeyPressed = useKeyPress('ArrowRight');
  const isArrowLeftKeyPressed = useKeyPress('ArrowLeft');

  const imagesById = useMemo(() => arrayToObject(images), [images]);

  const prevImage = useMemo(() => {
    if (lightboxImage === null) {
      return null;
    }

    const index = images.indexOf(lightboxImage);
    if (index < 0) {
      return null;
    }

    const prevIndex = index - 1;
    if (prevIndex < 0) {
      return images[images.length - 1];
    }

    return images[prevIndex];
  }, [lightboxImage, images]);

  const nextImage = useMemo(() => {
    if (lightboxImage === null) {
      return null;
    }

    const index = images.indexOf(lightboxImage);
    if (index < 0) {
      return null;
    }

    const nextIndex = index + 1;
    if (nextIndex >= images.length) {
      return images[0];
    }

    return images[nextIndex];
  }, [lightboxImage, images]);

  const clickThumbHandler = useCallback(
    (thumb) => {
      setLightboxImage(imagesById[thumb.id]);
    },
    [imagesById]
  );

  const clickPrevHandler = useCallback(() => {
    setLightboxImage(prevImage);
  }, [prevImage]);

  const clickNextHandler = useCallback(() => {
    setLightboxImage(nextImage);
  }, [nextImage]);

  const clickCloseHandler = useCallback(() => {
    setLightboxImage(null);
  }, []);

  const setImageDebounced = useCallback(
    debounce((image) => {
      setLightboxImage(image);
    }),
    []
  );

  useEffect(() => {
    if (isArrowLeftKeyPressed) {
      setImageDebounced(prevImage);
    }
  }, [isArrowLeftKeyPressed, setImageDebounced, prevImage]);

  useEffect(() => {
    if (isArrowRightKeyPressed) {
      setImageDebounced(nextImage);
    }
  }, [isArrowRightKeyPressed, setImageDebounced, nextImage]);

  return (
    <Fragment>
      <div className={styles.wrapper}>
        {thumbs.map((image, index) => (
          <Thumb key={index} image={image} onClick={clickThumbHandler} />
        ))}
      </div>
      {lightboxImage && (
        <Modal size="auto" showCloseButton={false} onClose={clickCloseHandler}>
          <div className={styles.lightbox}>
            <div className={styles.close}>
              <Button onClick={clickCloseHandler} type="rounded" color="white">
                <CloseIcon className={styles.svg} />
              </Button>
            </div>
            <div className={styles.image}>
              <GatsbyImage
                image={getProperty(lightboxImage, 'childImageSharp.gatsbyImageData')}
                alt=""
              />
            </div>
            <button onClick={clickPrevHandler} className={styles.prev}>
              <ArrowLeftIcon className={styles.svg} />
            </button>
            <button onClick={clickNextHandler} className={styles.next}>
              <ArrowRightIcon className={styles.svg} />
            </button>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  thumbs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
};

const Thumb = ({ image, onClick }) => {
  const clickHandler = useCallback(() => onClick(image), [image, onClick]);

  return (
    <button onClick={clickHandler} className={styles.thumb}>
      <GatsbyImage image={getProperty(image, 'childImageSharp.gatsbyImageData')} alt="" />
    </button>
  );
};

Thumb.propTypes = {
  image: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
