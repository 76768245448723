import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { getProperty, arrayToObject } from 'utils';

import { useFlatten } from './use-flatten';

export const useSubcategories = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.querySubcategoriesContents';

	return useFlatten(getProperty(results, key) || {}, (subcategories) =>
		subcategories.map((subcategory) => {
			const category = getProperty(subcategory, 'data.category.0');
			const page = getProperty(category, 'data.parent.0');

			const pagePath = getProperty(page, 'data.path');
			const categoryPath = getProperty(category, 'data.path');
			const subcategoryPath = getProperty(subcategory, 'data.path');

			subcategory.data.path = `${pagePath}${categoryPath}${subcategoryPath}`;

			return subcategory;
		})
	);
};

export const useSubcategoryById = (value) =>
	useSubcategories().find((subcategory) => {
		const subcategoryId = getProperty(subcategory, 'id');
		return subcategoryId === value;
	});

export const useSubcategoriesByCategoryId = (value) =>
	useSubcategories().filter((subcategory) => {
		const categoryId = getProperty(subcategory, 'data.category.0.id');
		return categoryId === value;
	});

export const useSubcategoryByAlias = (value) =>
	useSubcategories().find((feature) => getProperty(feature, 'data.alias') === value);

export const useSubcategoriesByAliases = (values) => {
	const valuesMap = useMemo(() => arrayToObject(values), [values]);

	return useSubcategories().filter(
		(feature) => getProperty(feature, 'data.alias') in valuesMap
	);
};

const query = graphql`
	query useSubcategoriesQuery {
		squidex {
			querySubcategoriesContents(top: 999, orderby: "data/rank/iv") {
				id
				data {
					name {
						de
					}
					alias {
						iv
					}
					path {
						de
					}
					title {
						de
					}
					text {
						de
					}
					category {
						iv {
							id
							data {
								path {
									de
								}
								parent {
									iv {
										data {
											path {
												de
											}
										}
									}
								}
							}
						}
					}
					description {
						de
					}
					info {
						de
					}
					illustration {
						iv {
							localFile {
								publicURL
							}
							...SquidexImageLocalFileProps
						}
					}
					image {
						iv {
							localFile {
								childImageSharp {
									gatsbyImageData(layout: CONSTRAINED, width: 475, quality: 100)
								}
							}
							...SquidexImageLocalFileProps
						}
					}
					filterTitle {
						de
					}
				}
			}
		}
	}
`;
