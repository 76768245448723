import { useEffect } from 'react';

import { isProduction } from 'utils';

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyA6y1eoGMknnoAZFHeHipit7ilJerpdJ_w',
  authDomain: 'we-tirol-website.firebaseapp.com',
  projectId: 'we-tirol-website',
  storageBucket: 'we-tirol-website.appspot.com',
  messagingSenderId: '139871128133',
  appId: '1:139871128133:web:6765aba756cff573cb712c',
  measurementId: 'G-08425M46PV',
};

const UC_EVENT = 'UC_EVENT';
const UC_SERVICE_ANALYTICS = 'Google Analytics for Firebase';

//
// Init Firebase with analytics after consent
//
// - https://docs.usercentrics.com/#/browser-ui
// - https://firebase.google.com/docs/web/setup
//
// Custom event trigger by UserCentrics after consent
// - https://admin.usercentrics.eu -> Implementation
//
export const Consent = () => {
  useEffect(() => {
    if (isProduction) {
      const script = document.createElement('script');
      script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
      script.dataset.settingsId = '6G5Zyc0cV';
      script.id = 'usercentrics-cmp';
      script.async = true;

      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      // no custom logging, only default events
      // get logged by GA4 service automatically
      const { initializeApp } = await import('firebase/app');
      const { getAnalytics } = await import('firebase/analytics');

      /* eslint-disable */
      const app = initializeApp(FIREBASE_CONFIG);
      const analytics = getAnalytics(app);
      /* eslint-enable */
    };

    const handler = (event) => {
      // user must have explicitly clicked on accept in consent and not
      // deactivated the google analytics for firebase service - event
      // gets triggered on initial page load and every consent update:
      // https://docs.usercentrics.com/#/v2-events
      const isExplicit = event.detail.type === 'explicit';
      const hasAnalytics = event.detail[UC_SERVICE_ANALYTICS] === true;

      if (isExplicit && hasAnalytics) {
        init();
      }
    };

    window.addEventListener(UC_EVENT, handler);
    return () => window.removeEventListener(UC_EVENT, handler);
  }, []);

  return null;
};
