import React from 'react';

import { LocaleProvider, ScrollProvider, ThemeProvider } from 'contexts';

const wrapRootElement = ({ element, props }) => (
  <LocaleProvider>
    <ScrollProvider>
      <ThemeProvider>{element}</ThemeProvider>
    </ScrollProvider>
  </LocaleProvider>
);

export default wrapRootElement;
