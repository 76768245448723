import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticJobsCareer = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryJobsCareerContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useJobsCareerQuery {
		squidex {
			queryJobsCareerContents {
				id
				data {
					seo {
						de {
							title
							description
						}
					}

					header {
						de {
							title
						}
					}

					content {
						de {
							title
							text
							linkUrl
							linkText
							image {
								localFile {
									id
									childImageSharp {
										gatsbyImageData(
											layout: CONSTRAINED
											width: 640
											height: 360
											quality: 100
											transformOptions: { cropFocus: ATTENTION }
										)
									}
								}
								...SquidexImageLocalFileProps
							}
						}
					}

					widget {
						de {
							title
							text
							linkUrl
							linkText
						}
					}

					infoContent {
						de {
							title
							text
						}
					}

					infoBlocks {
						de {
							title
							text
							linkUrl
						}
					}
				}
			}
		}
	}
`;
