import React, { Fragment } from 'react';

import { getProperty } from 'utils';
import { Text, Title, ContainerToMD } from 'components';
import { useStaticHours, useStaticFooter } from 'hooks';

import styles from './hours.module.scss';

export const Hours = () => {
  const staticHours = useStaticHours();
  const staticFooter = useStaticFooter();

  const items = getProperty(staticHours, 'data.items') || [];

  const title = getProperty(staticFooter, 'data.hours.0.title');
  const text = getProperty(staticFooter, 'data.hours.0.text');

  return (
    <div className={styles.wrapper}>
      <ContainerToMD>
        <Fragment>
          <Title size="sm" color="primary" type="special" wrapper="h3">
            {title}
          </Title>
          <div className={styles.header}>
            <Text>{text}</Text>
          </div>
          <div className={styles.content}>
            {items.map((item, index) => {
              const label = getProperty(item, 'label');
              const value = getProperty(item, 'value');

              return (
                <div key={index} className={styles.hour}>
                  <Text type="special" color="primary">
                    {label}
                  </Text>
                  <Text type="special" color="primary">
                    {value}
                  </Text>
                </div>
              );
            })}
          </div>
        </Fragment>
      </ContainerToMD>
    </div>
  );
};
