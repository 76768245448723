import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

export const ObfuscateLink = ({ href, onClick, children, ...props }) => {
  const clickHandler = useCallback(
    (event) => {
      event.preventDefault();
      window.location.href = href;
      onClick(event);
    },
    [onClick, href]
  );
  const protocol = useMemo(() => href.split(':')[0], [href]);

  return (
    <a onClick={clickHandler} href={`${protocol}:obfuscated`} {...props}>
      {children}
    </a>
  );
};

ObfuscateLink.defaultProps = {
  onClick: () => {},
};

ObfuscateLink.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string.isRequired,
};
