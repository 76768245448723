import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Input, InputDefaultProps, InputPropTypes } from './input';

// import styles from './textarea.module.scss';

export const Textarea = forwardRef(({ type, wrapper, ...props }, ref) => (
  <Input {...props} ref={ref} type={null} wrapper="textarea"/>
));

Textarea.defaultProps = {
  ...InputDefaultProps,
  rows: 15,
  cols: 5,
};

Textarea.propTypes = {
  ...InputPropTypes,
  rows: PropTypes.number,
  cols: PropTypes.number,
};
