import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'components';

import styles from './timeline.module.scss';

const SIZE = 10;

export const Timeline = ({ start, end, active, height }) => {
  const items = useMemo(() => {
    if (end % SIZE !== 0) {
      throw new Error(`'end' is not divisble by ${SIZE}!`);
    }

    if (start % SIZE !== 0) {
      throw new Error(`'start' is not divisble by ${SIZE}!`);
    }

    if (end <= start) {
      throw new Error(`'end' must be greater than 'start'!`);
    }

    const length = (end - start) / SIZE + 1;

    return Array.from({ length }).map((_, index) => {
      const value = start + SIZE * index;
      const subitems = Array.from({
        length: SIZE + 1,
      }).map((_, index) => value + index);

      return { value, subitems };
    });
  }, [start, end]);

  const wrapperStyle = useMemo(() => ({ height }), [height]);

  return (
    <div className={styles.wrapper} style={wrapperStyle}>
      <div className={styles.items}>
        {items.map(({ value, subitems }, index) => {
          return (
            <div
              key={index}
              className={`${styles.item} ${value === active ? styles.active : ''}`}>
              {value < end && (
                <div className={styles.subitems}>
                  {subitems.map((subvalue, subindex) => {
                    return (
                      <div
                        key={subindex}
                        className={`${styles.subitem} ${
                          subvalue === active ? styles.active : ''
                        }`}></div>
                    );
                  })}
                </div>
              )}
              <div className={styles.value}>
                <Text type="special" size="xs">{value}</Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Timeline.defaultProps = {
  start: 1900,
  end: 2000,
  height: 50,
};

Timeline.propTypes = {
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
  active: PropTypes.number,
};
