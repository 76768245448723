import { isObject, getProperty } from './object';

export const isArray = (value) => Array.isArray(value);

export const arrayToObject = (array, key = 'id', value = undefined) => {
  if (!isArray(array)) {
    throw new Error(`"array" must be an array!`);
  }

  return Object.assign(
    {},
    ...array.map((item) => {
      const obj = {};
      const prop =
        typeof key === 'function'
          ? key(item)
          : isObject(item)
          ? getProperty(item, key)
          : item;
      const val =
        value !== undefined
          ? typeof value === 'function'
            ? value(item)
            : value
          : isObject(item)
          ? item
          : true;

      obj[prop] = val;
      return obj;
    })
  );
}
