import { isClient } from './misc';

export const detectLanguage = () => {
  if (!isClient) {
    return null;
  }

  const { language, languages, userLanguage } = navigator;
  const detected = (languages && languages[0]) || userLanguage || language;

  if (typeof detected !== 'string') {
    return null;
  }

  return detected.substring(0, 2).toLowerCase();
};
