import { useEffect, useState } from 'react';

export const useMatchMedia = (expressions) => {
  const [matches, setMatches] = useState(() =>
    Array.from({ length: expressions.length }).map(() => false)
  );

  useEffect(() => {
    const teardowns = expressions.map((query, index) => {
      const handler = ({ matches }) =>
        setMatches((oldMatches) => {
          const newMatches = [...oldMatches];
          newMatches[index] = matches;
          return newMatches;
        });

      const mql = window.matchMedia(query);
      mql.addListener(handler);
      handler(mql);

      return () => mql.removeListener(handler);
    });

    return () => teardowns.forEach((teardown) => teardown());
  }, [expressions]);

  return matches;
};
