import React, { useMemo, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { CloseIcon } from 'icons';
import { Button, Card } from 'components';
import { useKeyPress, useIsMounted } from 'hooks';

import styles from './modal.module.scss';

export const Modal = ({
  header,
  footer,
  content,
  onClose,
  children,
  size,
  extraClassName,
  showCloseButton,
  closeOnBackdropClick,
  closeOnEscapeKeyPress,
}) => {
  const isEscapeKeyPressed = useKeyPress('Escape');
  const containerRef = useRef(null);
  const scrollerRef = useRef(null);
  const wrapperRef = useRef(null);
  const isMounted = useIsMounted();

  const closeHandler = useCallback(() => {
    if (typeof onClose !== 'function') {
      return;
    }

    onClose();
  }, [onClose]);

  const clickHandler = useCallback(
    (event) => {
      if (
        event.target !== containerRef.current &&
        event.target !== scrollerRef.current &&
        event.target !== wrapperRef.current
      ) {
        return;
      }

      if (!closeOnBackdropClick) {
        return;
      }

      closeHandler();
    },
    [wrapperRef, scrollerRef, closeOnBackdropClick, closeHandler]
  );

  const wrapperClassName = useMemo(() => [styles.wrapper, styles[size]].join(' '), [
    size,
  ]);
  const contentClassName = useMemo(() => [styles.content, extraClassName].join(' '), [
    extraClassName,
  ]);

  const closeButton = useMemo(
    () =>
      showCloseButton && (
        <Button type="rounded" color="white" onClick={closeHandler}>
          <CloseIcon className={styles.svg} />
        </Button>
      ),
    [showCloseButton, closeHandler]
  );

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    if (!closeOnEscapeKeyPress) {
      return;
    }

    if (!isEscapeKeyPressed) {
      return;
    }

    closeHandler();
  }, [isEscapeKeyPressed, closeOnEscapeKeyPress, closeHandler]);

  return isMounted
    ? createPortal(
        <div ref={wrapperRef} onClick={clickHandler} className={wrapperClassName}>
          <div ref={scrollerRef} className={styles.scroller}>
            <div ref={containerRef} className={styles.container}>
              <div className={contentClassName}>
                <Card
                  header={
                    header && (
                      <div className={styles.header}>
                        {header}
                        {closeButton}
                      </div>
                    )
                  }
                  footer={footer && <div className={styles.footer}>{footer}</div>}>
                  {!header && <div className={styles.close}>{closeButton}</div>}
                  {children}
                </Card>
              </div>
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};

Modal.defaultProps = {
  size: 'md',
  extraClassName: '',
  showCloseButton: true,
  closeOnBackdropClick: true,
  closeOnEscapeKeyPress: true,
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  extraClassName: PropTypes.string,
  showCloseButton: PropTypes.bool,
  closeOnBackdropClick: PropTypes.bool,
  closeOnEscapeKeyPress: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'auto']),
};
