import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { getProperty, arrayToObject } from 'utils';

import { useFlatten } from './use-flatten';

export const useCategories = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryCategoriesContents';

	return useFlatten(getProperty(results, key) || {}, (categories) =>
		categories.map((category) => {
			const pagePath = getProperty(category, 'data.parent.0.data.path');
			const categoryPath = getProperty(category, 'data.path');
			category.data.path = `${pagePath}${categoryPath}`;

			return category;
		})
	);
};

export const useCategoryById = (value) =>
	useCategories().find((category) => {
		const categoryId = getProperty(category, 'id');
		return categoryId === value;
	});

export const useCategoryByAlias = (value) =>
	useCategories().find((feature) => getProperty(feature, 'data.alias') === value);

export const useCategoriesByAliases = (values) => {
	const valuesMap = useMemo(() => arrayToObject(values), [values]);

	return useCategories().filter(
		(feature) => getProperty(feature, 'data.alias') in valuesMap
	);
};

const query = graphql`
	query useCategoriesQuery {
		squidex {
			queryCategoriesContents(top: 999, orderby: "data/rank/iv") {
				id
				data {
					name {
						de
					}
					alias {
						iv
					}
					path {
						de
					}
					parent {
						iv {
							data {
								path {
									de
								}
							}
						}
					}
					description {
						de
					}
					image {
						iv {
							localFile {
								childImageSharp {
									gatsbyImageData(layout: CONSTRAINED, width: 475, quality: 100)
								}
							}
							...SquidexImageLocalFileProps
						}
					}
				}
			}
		}
	}
`;
