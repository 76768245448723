import { graphql, useStaticQuery } from 'gatsby';

import { getProperty } from 'utils';

import { useFlatten } from './use-flatten';

export const useDepartments = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryDepartmentsContents';

	return useFlatten(getProperty(results, key) || {});
};

export const useDepartmentById = (value) =>
	useDepartments().find((department) => {
		const departmentId = getProperty(department, 'id');
		return departmentId === value;
	});

export const useDepartmentByAlias = (value) =>
	useDepartments().find((department) => {
		const departmentAlias = getProperty(department, 'data.alias');
		return departmentAlias === value;
	});

const query = graphql`
	query useDepartmentsQuery {
		squidex {
			queryDepartmentsContents(top: 999, orderby: "data/rank/iv") {
				id
				data {
					name {
						de
					}
					alias {
						iv
					}
					phone {
						iv
					}
				}
			}
		}
	}
`;
