import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import { getProperty } from 'utils';
import { useStaticFooter } from 'hooks';
import { ContainerFromMD } from 'components';

import { Hours } from './footer/hours';
import { Contact } from './footer/contact';

import styles from './footer.module.scss';

export const Footer = ({ withBackgroundImage }) => {
  const staticFooter = useStaticFooter();

  const backgroundImage =
    getProperty(
      staticFooter,
      'data.backgroundImage.0.localFile.childImageSharp.gatsbyImageData'
    ) || null;

  return (
    <Fragment>
      {withBackgroundImage && (
        <div className={styles.background}>
          <GatsbyImage image={backgroundImage} alt="" />
        </div>
      )}
      <footer className={styles.wrapper}>
        <ContainerFromMD>
          <div className={styles.container}>
            <Hours />
            <Contact />
          </div>
        </ContainerFromMD>
      </footer>
    </Fragment>
  );
};

Footer.defaultProps = {
  withBackgroundImage: true,
};

Footer.propTypes = {
  withBackgroundImage: PropTypes.bool,
};
