import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { LG, MD, getProperty } from 'utils';
import { Container, Text } from 'components';
import { useMatchMedia, usePagesByMenu, useFocusAreas, useCategories } from 'hooks';
import { LogoOutlineBigIcon } from 'icons';

import { Hamburger } from './hamburger';
import styles from './menu.module.scss';

const MEDIA_QUERIES = [`(min-width: ${MD}px)`, `(min-width: ${LG}px)`];

//
// MENU
//
export const Menu = ({ isOpen, onToggle, showNav }) => {
	const wrapperClassName = useMemo(
		() => [styles.wrapper, isOpen ? styles.visible : ''].filter(Boolean).join(' '),
		[isOpen]
	);
	const [isMD, isLG] = useMatchMedia(MEDIA_QUERIES);
	const mainPages = usePagesByMenu('main');
	const sidePages = usePagesByMenu('side');
	const focusAreas = useFocusAreas();
	const categories = useCategories();

	return (
		<div className={wrapperClassName}>
			<button onClick={onToggle} className={styles.toggle}>
				<Hamburger isActive={isOpen} className={styles.hamburger} />
			</button>
			<nav className={styles.overlay}>
				<Container>
					<div className={styles.items}>
						{!isLG && (
							<div className={styles.mainPages}>
								{mainPages.map((page) => {
									const id = getProperty(page, 'id');
									const path = getProperty(page, 'data.path');
									const title = getProperty(page, 'data.title');

									return (
										<Link
											key={id}
											to={path}
											partiallyActive={true}
											className={styles.link}
											activeClassName={styles.active}>
											<Text size="md" weight="bold">
												{title}
											</Text>
										</Link>
									);
								})}
							</div>
						)}
						<div className={styles.focusAreas}>
							{focusAreas.map((focusArea) => {
								const id = getProperty(focusArea, 'id');
								const path = getProperty(focusArea, 'data.path');
								const name = getProperty(focusArea, 'data.name');

								return (
									<Link
										key={id}
										to={path}
										className={styles.link}
										activeClassName={styles.active}>
										<Text size="md" weight="bold">
											{name}
										</Text>
									</Link>
								);
							})}
						</div>
						<div className={styles.categories}>
							{categories.map((category) => {
								const id = getProperty(category, 'id');
								const path = getProperty(category, 'data.path');
								const name = getProperty(category, 'data.name');

								return (
									<Link
										key={id}
										to={path}
										className={styles.link}
										activeClassName={styles.active}>
										<Text size="md" weight="bold">
											{name}
										</Text>
									</Link>
								);
							})}
						</div>
						<div className={styles.sidePages}>
							{sidePages.map((page) => {
								const id = getProperty(page, 'id');
								const path = getProperty(page, 'data.path');
								const title = getProperty(page, 'data.title');

								return (
									<Link
										key={id}
										to={path}
										className={styles.link}
										activeClassName={styles.active}>
										<Text size="md" weight="bold">
											{title}
										</Text>
									</Link>
								);
							})}
						</div>
					</div>
					<LogoOutlineBigIcon className={styles.logo} />
				</Container>
			</nav>
			{isMD && (
				<div
					className={styles.backdrop}
					onClick={onToggle}
					tabIndex="-1"
					role="button"></div>
			)}
			{isOpen && (
				<Helmet>
					<body className={styles.body} />
				</Helmet>
			)}
		</div>
	);
};

Menu.defaultProps = {
	isOpen: false,
	onToggle: () => {},
};

Menu.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
};
