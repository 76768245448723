import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty, arrayToObject } from 'utils';

export const useFeatures = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryFeaturesContents';

	return useFlatten(getProperty(results, key) || {});
};

export const useFeatureById = (value) =>
	useFeatures().find((feature) => {
		const featureId = getProperty(feature, 'id');
		return featureId === value;
	});

export const useFeatureByAlias = (value) =>
	useFeatures().find((feature) => getProperty(feature, 'data.alias') === value);

export const useFeaturesByAliases = (values) => {
	const valuesMap = useMemo(() => arrayToObject(values), [values]);

	return useFeatures().filter(
		(feature) => getProperty(feature, 'data.alias') in valuesMap
	);
};

const query = graphql`
	query useFeaturesQuery {
		squidex {
			queryFeaturesContents(top: 999, orderby: "data/rank/iv") {
				id
				data {
					name {
						de
					}
					alias {
						iv
					}
				}
			}
		}
	}
`;
