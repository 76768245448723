import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticAdministrate = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryAdministrateContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useAdministrateQuery {
		squidex {
			queryAdministrateContents {
				id
				data {
					seo {
						de {
							title
							description
						}
					}

					header {
						de {
							title
						}
					}

					content {
						de {
							title
							text
							backgroundImage {
								localFile {
									childImageSharp {
										gatsbyImageData(layout: CONSTRAINED, width: 375, quality: 100)
									}
								}
								...SquidexImageLocalFileProps
							}
						}
					}

					servicesContent {
						de {
							title
						}
					}

					servicesItems {
						de {
							title
							text
						}
					}

					condominiumContent {
						de {
							title
						}
					}

					condominiumItems {
						de {
							title
							text
						}
					}

					info {
						de {
							linkInformation {
								data {
									path {
										de
									}
								}
							}
							linkInformationText
							contactInformation
						}
					}

					background {
						de {
							backgroundImage {
								localFile {
									childImageSharp {
										gatsbyImageData(layout: FULL_WIDTH, quality: 100)
									}
								}
								...SquidexImageLocalFileProps
							}
						}
					}
				}
			}
		}
	}
`;
