import { forwardRef, useState, useEffect, useContext, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { CircleMarker, DivIcon } from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';

import { MapContext } from './map-container';
import { MAP_CLUSTER_DEFAULT_OPTIONS } from './options';

import styles from './map-cluster.module.scss';

// export const MAP_CLUSTER_DEFAULT_OPTIONS = {
//   animate: true,
//   maxClusterRadius: 80,
//   disableClusteringAtZoom: 18,
//   animateAddingMarkers: true,
//   spiderfyOnMaxZoom: false,
//   zoomToBoundsOnClick: true,
//   showCoverageOnHover: false,
// };

export const MapCluster = forwardRef(
  ({ children, latLngs, options, iconFactory, markerFactory, onInitialized }, ref) => {
    const [cluster, setCluster] = useState(null);
    const map = useContext(MapContext);

    useImperativeHandle(ref, () => cluster, [cluster]);

    useEffect(() => {
      if (map === null) {
        return;
      }

      const cluster = new MarkerClusterGroup({
        ...{
          iconCreateFunction: iconFactory,
        },
        ...options,
      });
      setCluster(cluster);

      const markers = latLngs.map(markerFactory);
      cluster.addLayers(markers);
      map.addLayer(cluster);

      const teardown = onInitialized(map, cluster);

      return () => {
        if (typeof teardown === 'function') {
          teardown();
        }

        cluster.clearLayers();
        cluster.remove();
        setCluster(null);
      };
    }, [map, latLngs, options, iconFactory, markerFactory, onInitialized]);

    return children;
  }
);

MapCluster.defaultProps = {
  children: null,
  options: MAP_CLUSTER_DEFAULT_OPTIONS,
  iconFactory: (cluster) =>
    new DivIcon({
      iconSize: [25, 25],
      className: styles.cluster,
      html: `<div class="${styles.icon}">${cluster.getChildCount()}</div>`,
    }),
  markerFactory: (latLng) =>
    new CircleMarker(latLng, {
      fillColor: 'white',
      fillOpacity: 1,
      stroke: false,
      radius: 10,
    }),
  onInitialized: (/* map, cluster */) => {},
};

MapCluster.propTypes = {
  latLngs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  options: PropTypes.shape({
    maxClusterRadius: PropTypes.number,
    disableClusteringAtZoom: PropTypes.number,
    animate: PropTypes.bool,
    animateAddingMarkers: PropTypes.bool,
    spiderfyOnMaxZoom: PropTypes.bool,
    zoomToBoundsOnClick: PropTypes.bool,
    showCoverageOnHover: PropTypes.bool,
  }),
  iconFactory: PropTypes.func,
  markerFactory: PropTypes.func,
  onInitialized: PropTypes.func,
};
