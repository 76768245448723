import { forwardRef, useState, useEffect, useContext, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { CircleMarker } from 'leaflet';

import { MapContext } from './map-container';
import { MAP_CIRCLE_MARKER_DEFAULT_OPTIONS } from './options';

// export const MAP_CIRCLE_MARKER_DEFAULT_OPTIONS = {
//   fillColor: 'white',
//   fillOpacity: 1,
//   stroke: false,
//   radius: 10,
//   interactive: true,
// };

export const MapCircleMarker = forwardRef(({ latLng, options, onInitialized }, ref) => {
  const [circleMarker, setCircleMarker] = useState(null);
  const map = useContext(MapContext);

  useImperativeHandle(ref, () => circleMarker, [circleMarker]);

  useEffect(() => {
    if (map === null) {
      return;
    }

    const circleMarker = new CircleMarker(latLng, options);
    setCircleMarker(circleMarker);
    map.addLayer(circleMarker);

    const teardown = onInitialized(map, circleMarker);

    return () => {
      if (typeof teardown === 'function') {
        teardown();
      }

      circleMarker.remove();

      setCircleMarker(null);
    };
  }, [map, latLng, options, onInitialized]);

  return null;
});

MapCircleMarker.defaultProps = {
  latLng: null,
  options: MAP_CIRCLE_MARKER_DEFAULT_OPTIONS,
  onInitialized: (/* map, circleMarker */) => {},
};

MapCircleMarker.propTypes = {
  latLng: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.shape({
    fillColor: PropTypes.string,
    fillOpacity: PropTypes.number,
    stroke: PropTypes.bool,
    radius: PropTypes.number,
  }),
  onInitialized: PropTypes.func,
};
