import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticWhistleblowerProtection = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryWhistleblowerProtectionContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useWhistleblowerProtectionQuery {
		squidex {
			queryWhistleblowerProtectionContents {
				id
				data {
					seo {
						de {
							title
							description
						}
					}

					header {
						de {
							title
						}
					}

					content {
						de {
							title
							text
						}
					}

					items {
						de {
							title
							text
						}
					}
				}
			}
		}
	}
`;
