import React from 'react';
import { Link } from 'gatsby';

import { usePagesByMenu } from 'hooks';
import { getProperty } from 'utils';
import { Text } from 'components';

import styles from './nav.module.scss';

//
// MENU
//
export const Nav = () => {
  const pages = usePagesByMenu('main');

  return (
    <nav className={styles.wrapper}>
      {pages.map((page) => {
        const id = getProperty(page, 'id');
        const path = getProperty(page, 'data.path');
        const title = getProperty(page, 'data.title');

        return (
          <Link
            key={id}
            to={path}
            partiallyActive={true}
            className={styles.item}
            activeClassName={styles.active}>
            <Text size="sm" weight="bold">
              <span className={styles.label}>{title}</span>
            </Text>
          </Link>
        );
      })}
    </nav>
  );
};
