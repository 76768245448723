import { graphql, useStaticQuery } from 'gatsby';

import { getProperty } from 'utils';

import { useFlatten } from './use-flatten';

export const useDownloads = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryDownloadsContents';

	return useFlatten(getProperty(results, key) || {});
};

export const useDownloadById = (value) =>
	useDownloads().find((download) => {
		const downloadId = getProperty(download, 'id');
		return downloadId === value;
	});

const query = graphql`
	query useDownloadsQuery {
		squidex {
			queryDownloadsContents(top: 999) {
				id
				data {
					name {
						de
					}
					files {
						de {
							name
							file {
								url
							}
						}
					}
					downloadareas {
						iv {
							id
						}
					}
				}
			}
		}
	}
`;
