import Pin from './pin.svg';

export const MAP_CIRCLE_MARKER_DEFAULT_OPTIONS = {
  fillColor: 'white',
  fillOpacity: 1,
  stroke: false,
  radius: 10,
  interactive: true,
};

export const MAP_CLUSTER_DEFAULT_OPTIONS = {
  animate: true,
  maxClusterRadius: 80,
  // disableClusteringAtZoom: 15,
  animateAddingMarkers: true,
  spiderfyOnMaxZoom: true,
  zoomToBoundsOnClick: true,
  showCoverageOnHover: false,
};

export const MAP_CONTAINER_DEFAULT_OPTIONS = {
  prefix: false,
  attributionControl: false,
  zoomControl: false,
  boxZoom: false,
  doubleClickZoom: false,
  dragging: false,
  keyboard: false,
  scrollWheelZoom: false,
  tap: false,
  touchZoom: false,
  zoomSnap: 0,
  zoomDelta: 1,
  maxBoundsViscosity: 1,
};

export const MAP_GEO_JSON_DEFAULT_OPTIONS = {
  style: {
    fillColor: 'white',
    color: 'white',
    fillOpacity: 1,
    opacity: 1,
    weight: 1,
  },
  interactive: true,
};

export const MAP_MARKER_DEFAULT_OPTIONS = {
  iconUrl: Pin,
  iconSize: [50, 75],
};

export const MAP_TILE_LAYER_DEFAULT_OPTIONS = {
  minZoom: 0,
  maxZoom: 18,
  attribution: null,
};
