import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './text.module.scss';

import { HTML } from 'components';

export const Text = ({
  wrapper: Wrapper,
  color,
  type,
  size,
  weight,
  children,
  className,
  newLineToBreak,
  ...props
}) => {
  const wrapperClassName = useMemo(
    () =>
      [styles.wrapper, styles[type], styles[color], styles[size], styles[weight]].join(
        ' '
      ),
    [type, color, size, weight]
  );

  const processed = useMemo(
    () =>
      typeof children === 'string' && newLineToBreak === true ? (
        <HTML content={children.replace(/(?:\r\n|\r|\n)/g, '<br>')} />
      ) : (
        children
      ),
    [children, newLineToBreak]
  );

  return (
    <Wrapper className={wrapperClassName} {...props}>
      {processed}
    </Wrapper>
  );
};

Text.defaultProps = {
  size: 'md',
  type: 'default',
  color: 'inherit',
  weight: 'normal',
  wrapper: 'div',
};

Text.propTypes = {
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  type: PropTypes.oneOf(['default', 'special']),
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'black', 'inherit']),
  weight: PropTypes.oneOf(['normal', 'bold']),
  wrapper: PropTypes.oneOf(['div', 'p', 'span']),
  newLineToBreak: PropTypes.bool,
};
