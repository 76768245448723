import { graphql, useStaticQuery } from 'gatsby';

import { getProperty, arrayToObject } from 'utils';

import { useFlatten } from './use-flatten';
import { useFeatureByAlias } from './use-features';
import { useStaticShared } from './use-static-shared';

export const FREE_UNITS_ALIAS = 'free-units';
export const NO_FREE_UNITS_ALIAS = 'no-free-units';
export const MUNICIPAL_ALIAS = 'municipal';

export const UNIT_TYPE_RENT = 'Miete';
export const UNIT_TYPE_HIRE_PURCHASE = 'Mietkauf';
export const UNIT_TYPE_PROPERTY = 'Eigentum';

export const UNIT_AVAILABILITY_AVAILABLE = 'Verfügbar';
export const UNIT_AVAILABILITY_ASSIGNED = 'Vergeben';
export const UNIT_AVAILABILITY_RESERVED = 'Reserviert';

export const useProjects = () => {
	const staticShared = useStaticShared();
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryProjectsContents';

	return useFlatten(getProperty(results, key) || {}, (projects) =>
		projects.map((project) => {
			const pathPrefix = getProperty(staticShared, 'data.projects.0.pathPrefix');
			const path = getProperty(project, 'data.path');
			project.data.path = `${pathPrefix}${path}`;

			return project;
		})
	);
};

export const useProjectById = (value) =>
	useProjects().find((project) => {
		const projectId = getProperty(project, 'id');
		return projectId === value;
	});

export const useProjectHasFreeUnits = (project) => {
	const feature = useFeatureByAlias(FREE_UNITS_ALIAS);

	return hasFeature(project, feature);
};

export const useProjectHasNoFreeUnits = (project) => {
	const feature = useFeatureByAlias(NO_FREE_UNITS_ALIAS);

	return hasFeature(project, feature);
};

export const useProjectHasMunicipal = (project) => {
	const feature = useFeatureByAlias(MUNICIPAL_ALIAS);

	return hasFeature(project, feature);
};

export const useProjectsWithFocusAreas = () =>
	useProjects().filter((project) => {
		const focusAreas = getProperty(project, 'data.focusAreas');
		return focusAreas !== null;
	});

export const useProjectsByFocusAreaId = (value) =>
	useProjects().filter((project) => {
		const focusAreasById = arrayToObject(getProperty(project, 'data.focusAreas') || []);

		return value in focusAreasById;
	});

export const useProjectsBySubcategoryId = (value) =>
	useProjects().filter((project) => {
		const subcategoriesById = arrayToObject(
			getProperty(project, 'data.subcategories') || []
		);

		return value in subcategoriesById;
	});

export const useProjectsByCategoryId = (value) =>
	useProjects().filter((project) => {
		const subcategories = getProperty(project, 'data.subcategories') || [];
		const categoryIds = subcategories.map((subcategory) =>
			getProperty(subcategory, 'data.category.0.id')
		);
		const categoriesById = arrayToObject(categoryIds);

		return value in categoriesById;
	});

const hasFeature = (project, feature) => {
	const features = getProperty(project, 'data.features') || [];
	return getProperty(feature, 'id') in arrayToObject(features);
};

const query = graphql`
	query useProjectsQuery {
		squidex {
			queryProjectsContents(top: 999) {
				id
				data {
					name {
						de
					}
					path {
						de
					}
					subcategories {
						iv {
							id
							data {
								category {
									iv {
										id
									}
								}
							}
						}
					}
					focusAreas {
						iv {
							id
						}
					}
					status {
						iv
					}
					statusDate {
						iv
					}
					features {
						iv {
							id
							data {
								name {
									de
								}
							}
						}
					}

					municipalContact {
						iv
					}

					address {
						de {
							street
							zip
							city
						}
					}
					position {
						iv
					}

					contact {
						de {
							name
							phone
							mobile
							email
						}
					}

					facts {
						de {
							line
						}
					}

					title {
						de
					}

					description {
						de
					}

					text {
						de
					}

					tags {
						de
					}

					units {
						de {
							type
							top
							floor
							squareMeters
							rooms
							misc
							priceCurrent
							priceInitial
							plan {
								url
							}
							availability
						}
					}

					folder {
						de {
							name
							file {
								url
							}
						}
					}

					downloads {
						de {
							name
							file {
								url
							}
						}
					}

					furtherInformation {
						de
					}

					imagesSmall: images {
						iv {
							localFile {
								id
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										width: 400
										height: 400
										quality: 100
										transformOptions: { cropFocus: ATTENTION }
									)
								}
							}
							...SquidexImageLocalFileProps
						}
					}

					imagesLarge: images {
						iv {
							localFile {
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										width: 1050
										height: 700
										quality: 100
										transformOptions: { cropFocus: ATTENTION }
									)
								}
							}
							...SquidexImageLocalFileProps
						}
					}
				}
			}
		}
	}
`;
