import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticFooter = ({ menu } = {}) => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryFooterContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useFooterQuery {
		squidex {
			queryFooterContents {
				id
				data {
					backgroundImage {
						iv {
							localFile {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH, quality: 100)
								}
							}
							...SquidexImageLocalFileProps
						}
					}

					hours {
						de {
							title
							text
						}
					}

					contact {
						de {
							title
						}
					}
				}
			}
		}
	}
`;
