import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const INFOBOX_TYPE_STANDARD = 'Standard';
export const INFOBOX_TYPE_WARNING = 'Warnung';
export const INFOBOX_TYPE_CONDOLENCE = 'Kondolenz';

export const useStaticInfobox = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryInfoboxContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useInfoboxQuery {
		squidex {
			queryInfoboxContents {
				id
				data {
					title {
						de
					}

					text {
						de
					}

					type {
						iv
					}

					isActive {
						iv
					}
				}
				lastModified
			}
		}
	}
`;
