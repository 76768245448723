import React, { useCallback, useState } from 'react';

import { WorldIcon } from 'icons';
import { Button, Text } from 'components';
import { useStaticShared } from 'hooks';
import { getCookie, setCookie, getProperty } from 'utils';

import styles from './map-consent.module.scss';

const COOKIE_KEY = 'accept_osm';
const COOKIE_VAL = 'ok';

export const MapConsent = ({ children }) => {
  const staticShared = useStaticShared();
  const privacyHint = getProperty(staticShared, 'data.map.0.privacyHint');
  const privacyButton = getProperty(staticShared, 'data.map.0.privacyButton');

  const [hasAccepted, setHasAccepted] = useState(() => {
    const cookie = getCookie(COOKIE_KEY);
    return cookie === COOKIE_VAL;
  }, []);

  const clickHandler = useCallback(() => {
    setCookie(COOKIE_KEY, COOKIE_VAL, 365);
    setHasAccepted(true);
  }, []);

  return !hasAccepted ? (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.text}>
          <Text size="sm">{privacyHint}</Text>
        </div>
        <Button type="minimal" onClick={clickHandler}>{privacyButton}</Button>
      </div>
      <WorldIcon className={styles.svg} />
    </div>
  ) : (
    children
  );
};
