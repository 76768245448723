import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { getProperty } from 'utils';
import { useLocale, useStaticShared } from 'hooks';

export const MetaTags = ({ page, title, description }) => {
	const staticShared = useStaticShared();
	const { locale } = useLocale();

	const companyName = getProperty(staticShared, 'data.meta.0.companyName');
	const pageLabel = getProperty(staticShared, 'data.meta.0.page');

	const pageText = `${pageLabel} ${page}`;

	const metaDescription = description;
	const metaTitle = useMemo(() => {
		const titles = [title];

		if (page > 1) {
			titles.push(pageText);
		}

		titles.push(companyName);

		return titles.join(' | ');
	}, [title, page, pageText, companyName]);

	return (
		<Helmet>
			<html lang={locale} />
			<title>{metaTitle}</title>
			<meta name="robots" content="index, follow" />
			<meta name="description" content={metaDescription} />
			<meta name="og:locale" content={locale} />
			<meta name="og:type" content="website" />
			<meta name="og:title" content={metaTitle} />
			<meta name="og:description" content={metaDescription} />
			<meta name="twitter:title" content={metaTitle} />
			<meta name="twitter:description" content={metaDescription} />
			<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v2" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v2" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v2" />
			<link rel="manifest" href="/site.webmanifest" />
			<link rel="mask-icon" href="/safari-pinned-tab.svg?v2" color="#ff6316" />
			<meta name="msapplication-TileColor" content="#191919" />
			<meta name="theme-color" content="#ffffff" />
		</Helmet>
	);
};

MetaTags.defaultProps = {
	page: 1,
};

MetaTags.propTypes = {
	page: PropTypes.number,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};
