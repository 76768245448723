import React, {
  forwardRef,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import styles from './input.module.scss';

export const InputDefaultProps = {
  value: '',
  size: 'md',
  type: 'text',
  focus: false,
  placeholder: '',
  wrapper: 'input',
  paddingLeft: null,
  paddingRight: null,
  onBlur: () => {},
  onFocus: () => {},
  onInput: () => {},
  onChange: () => {},
};

export const InputPropTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  type: PropTypes.string,
  focus: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
};

export const Input = forwardRef(
  (
    {
      wrapper: Wrapper,
      value,
      size,
      type,
      focus,
      paddingLeft,
      paddingRight,
      placeholder,
      onInput,
      onChange,
      onBlur,
      onFocus,
      ...props
    },
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState(value);
    const inputRef = useRef(null);

    const wrapperClassName = useMemo(() => [styles.wrapper, styles[size]].join(' '), [
      size,
    ]);
    const wrapperStyle = useMemo(() => ({ paddingLeft, paddingRight }), [
      paddingLeft,
      paddingRight,
    ]);

    const inputHandler = useCallback(
      ({ target: { value } }) => {
        setCurrentValue(value);
        onInput(value);
      },
      [onInput]
    );

    const changeHandler = useCallback(
      ({ target: { value } }) => {
        setCurrentValue(value);
        onChange(value);
      },
      [onChange]
    );

    useImperativeHandle(ref, () => inputRef.current);

    useEffect(() => {
      if (focus) {
        inputRef.current.focus();
      }
    }, [focus]);

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    return (
      <Wrapper
        type={type}
        ref={inputRef}
        value={currentValue}
        onBlur={onBlur}
        onFocus={onFocus}
        onInput={inputHandler}
        onChange={changeHandler}
        placeholder={placeholder}
        style={wrapperStyle}
        className={wrapperClassName}
        {...props}
      />
    );
  }
);

Input.defaultProps = {
  ...InputDefaultProps
};

Input.propTypes = {
  ...InputPropTypes
};
