import React, { useMemo } from 'react';

import styles from './list.module.scss';

export const Ul = ({ children, noMargin = false }) => {
  const wrapperClassName = useMemo(
    () => [styles.ul, noMargin ? styles.noMargin : ''].join(' '),
    [noMargin]
  );
  return <ul className={wrapperClassName}>{children}</ul>;
};

export const Ol = ({ children, noMargin = false }) => {
  const wrapperClassName = useMemo(
    () => [styles.ol, noMargin ? styles.noMargin : ''].join(' '),
    [noMargin]
  );
  return <ol className={wrapperClassName}>{children}</ol>;
};

export const Li = ({ children }) => <li className={styles.li}>{children}</li>;
