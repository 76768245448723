export const joinPaths = parts => {
  if (!Array.isArray(parts)) {
    throw new Error(`"paths" must be an array!`);
  }

  parts = parts.map(part => part.trim());
  parts = parts.join('/');

  return resolvePath(parts);
};

export const resolvePath = urlOrPath => {
  if (typeof urlOrPath !== 'string') {
    throw new Error(`"urlOrPath" must be a string!`);
  }

  let resolved = urlOrPath;

  try {
    ({ href: resolved } = new URL(urlOrPath));
  } catch (e) {
    resolved = normalizePath(urlOrPath);
  }

  return resolved;
};

export const normalizePath = path => {
  if (typeof path !== 'string') {
    throw new Error(`"path" must be a string!`);
  }

  // resolve relative paths: "abc/../" -> ""
  const regex = /[^/.]+\/+\.\./g;

  let match;
  while ((match = path.match(regex))) {
    path = path.replace(match[0], '');
  }

  // normalize double slashes: "//" -> "/"
  path = path.replace(/\/{2,}/g, '/');

  // remove dot slash paths: "./" -> ""
  path = path.replace(/(?:^\.\/|([^.])\.\/)/g, '$1');

  return path;
};

export const trimSlashes = path => {
  if (typeof path !== 'string') {
    throw new Error(`"path" must be a string!`);
  }

  return path.replace(/^[/]*|[/]*$/g, '');
};

export const isURL = value => {
  try {
    new URL(value);
  } catch(e) {
    return false;
  }

  return true;
}
