import { graphql, useStaticQuery } from 'gatsby';

import { getProperty } from 'utils';

import { useFlatten } from './use-flatten';
import { usePageByAlias } from './use-pages';

export const useArticles = () => {
	const parentPage = usePageByAlias('news');
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryArticlesContents';

	return useFlatten(getProperty(results, key) || {}, (articles) =>
		articles.map((article) => {
			const pagePath = getProperty(parentPage, 'data.path');
			const articlePath = getProperty(article, 'data.path');
			article.data.path = `${pagePath}${articlePath}`;

			return article;
		})
	);
};

export const useArticleById = (value) =>
	useArticles().find((article) => {
		const articleId = getProperty(article, 'id');
		return articleId === value;
	});

export const useArticlePaginationById = (value) => {
	const articles = useArticles();
	const max = articles.length;
	let prevArticle = undefined;
	let nextArticle = undefined;

	for (let i = 0; i < max; i++) {
		prevArticle = i > 0 ? articles[i - 1] : undefined;
		nextArticle = i < max ? articles[i + 1] : undefined;
		const articleId = getProperty(articles, `${i}.id`);
		if (articleId === value) {
			break;
		}
	}

	return [prevArticle, nextArticle];
};

const query = graphql`
	query useArticlesQuery {
		squidex {
			queryArticlesContents(top: 999) {
				id
				data {
					path {
						de
					}

					title {
						de
					}

					description {
						de
					}

					text {
						de
					}

					project {
						iv {
							id
						}
					}

					isHint {
						iv
					}

					imagesTeaser: images {
						iv {
							localFile {
								id
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										width: 768
										height: 400
										quality: 100
									)
								}
							}
							...SquidexImageLocalFileProps
						}
					}

					imagesSmall: images {
						iv {
							localFile {
								id
								childImageSharp {
									gatsbyImageData(layout: CONSTRAINED, width: 636, quality: 100)
								}
							}
							...SquidexImageLocalFileProps
						}
					}

					imagesLarge: images {
						iv {
							localFile {
								id
								childImageSharp {
									gatsbyImageData(layout: CONSTRAINED, width: 1050, quality: 100)
								}
							}
							...SquidexImageLocalFileProps
						}
					}
				}
			}
		}
	}
`;
