import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticInfo = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryInfoContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useInfoQuery {
		squidex {
			queryInfoContents {
				id
				data {
					seo {
						de {
							title
							description
						}
					}

					header {
						de {
							title
						}
					}

					content {
						de {
							title
							text
							noInfos
							noFilesInInfo
						}
					}
				}
			}
		}
	}
`;
