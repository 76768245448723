import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useStaticShared } from 'hooks';
import { getProperty } from 'utils';

import { Text } from 'components';

import { ArrowLeftIcon } from 'icons';

import styles from './back-link.module.scss';

export const BackLink = ({ wrapper: Wrapper, children, color, reverse, ...props }) => {
  const staticShared = useStaticShared();

  const backToOverview = getProperty(staticShared, 'data.buttons.0.backToOverview');

  const wrapperclassName = useMemo(
    () => [styles.wrapper, styles[color], reverse ? styles.reverse : ''].join(' '),
    [color, reverse]
  );

  const content = useMemo(() => children || backToOverview, [children, backToOverview]);

  return (
    <Wrapper className={wrapperclassName} {...props}>
      <span className={styles.icon}>
        <ArrowLeftIcon />
      </span>
      <span className={styles.text}>
        <Text size="md" weight="bold">
          {content}
        </Text>
      </span>
    </Wrapper>
  );
};

BackLink.defaultProps = {
  wrapper: 'div',
  color: 'primary',
  reverse: false,
};

BackLink.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'black']),
  path: PropTypes.string,
  reverse: PropTypes.bool,
  wrapper: PropTypes.elementType,
};
