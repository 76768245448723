import React from 'react';
import { Link } from 'gatsby';

import { LogoIcon } from 'icons'

import styles from './logo.module.scss';

export const Logo = () => (
  <Link className={styles.wrapper} to="/">
    <LogoIcon className={styles.svg} />
  </Link>
);
