import React, {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { getProperty } from 'utils';
import { CheckmarkCheckedIcon, CheckmarkUncheckedIcon } from 'icons';

import { Input, InputDefaultProps, InputPropTypes } from './input';

import styles from './checkbox.module.scss';

export const CheckboxDefaultProps = {
  ...InputDefaultProps,
  checked: false,
  type: 'checkbox',
  iconChecked: CheckmarkCheckedIcon,
  iconUnchecked: CheckmarkUncheckedIcon,
};

export const CheckboxPropTypes = {
  ...InputPropTypes,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  checked: PropTypes.bool,
  iconChecked: PropTypes.elementType,
  iconUnchecked: PropTypes.elementType,
};

export const Checkbox = forwardRef(
  (
    {
      id,
      label,
      checked,
      onChange,
      iconChecked: IconChecked,
      iconUnchecked: IconUnchecked,
      ...props
    },
    ref
  ) => {
    const [currentChecked, setCurrentChecked] = useState(checked);
    const inputRef = useRef(null);

    const changeHandler = useCallback(() => {
      const checked = getProperty(inputRef, 'current.checked');
      setCurrentChecked(checked);
      onChange(checked);
    }, [onChange]);

    useImperativeHandle(ref, () => inputRef.current);

    useEffect(() => {
      setCurrentChecked(checked);
    }, [checked]);

    return (
      <label className={styles.wrapper} htmlFor={id}>
        <div className={styles.icon}>
          {currentChecked ? (
            <IconChecked className={styles.svg} />
          ) : (
            <IconUnchecked className={styles.svg} />
          )}
        </div>
        <div className={styles.text}>{label}</div>
        <div className={styles.input}>
          <Input
            {...props}
            id={id}
            ref={inputRef}
            checked={currentChecked}
            onChange={changeHandler}
          />
        </div>
      </label>
    );
  }
);

Checkbox.defaultProps = {
  ...CheckboxDefaultProps,
};

Checkbox.propTypes = {
  ...CheckboxPropTypes,
};
