import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './tooltip.module.scss';

export const Tooltip = ({
  isVisible,
  children,
  position,
  color,
  size,
  text,
  ...props
}) => {
  const wrapperclassName = useMemo(
    () => [styles.wrapper, isVisible === undefined ? styles.hover : ''].join(' '),
    [isVisible]
  );

  const tooltipclassName = useMemo(
    () =>
      [
        styles.tooltip,
        styles[size],
        styles[color],
        styles[position],
        isVisible ? styles.visible : '',
      ].join(' '),
    [isVisible, position, color, size]
  );

  return (
    <div className={wrapperclassName}>
      <div className={tooltipclassName}>{text}</div>
      {children}
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'topLeft',
  color: 'primary',
  size: 'auto',
};

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isVisible: PropTypes.bool,
  size: PropTypes.oneOf(['auto', 'small', 'medium', 'large', 'fit']),
  color: PropTypes.oneOf(['primary', 'secondary', 'grey', 'white']),
  position: PropTypes.oneOf([
    'topLeft',
    'top',
    'topRight',
    'right',
    'bottomRight',
    'bottom',
    'bottomLeft',
    'left',
  ]),
};
