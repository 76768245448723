import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './obfuscate-text.module.scss';

export const ObfuscateText = ({ text, className }) => {
  const processedClassName = useMemo(() => [className, styles.obfuscated].join(' '), [
    className,
  ]);
  const processedText = useMemo(() => text.split('').reverse().join(''), [text]);

  return <span className={processedClassName}>{processedText}</span>;
};

ObfuscateText.propTypes = {
  text: PropTypes.string.isRequired,
};
