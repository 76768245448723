export const isValid = (value) => {
  if (typeof value !== 'string') {
    return false;
  }

  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[\s0-9/.-]*$/.test(value);
};

export const toUri = string => {
	return isValid(string) ? `tel:${string.replace(/[^\d+]/g, '')}` : null;
};
