import React from 'react';

import styles from './container.module.scss';

export const Container = ({ className, ...props }) => (
  <div className={styles.default} {...props} />
);

export const ContainerFromXS = ({ className, ...props }) => (
  <div className={styles.fromXs} {...props} />
);

export const ContainerToXS = ({ className, ...props }) => (
  <div className={styles.toXs} {...props} />
);

export const ContainerFromSM = ({ className, ...props }) => (
  <div className={styles.fromSm} {...props} />
);

export const ContainerToSM = ({ className, ...props }) => (
  <div className={styles.toSm} {...props} />
);

export const ContainerFromMD = ({ className, ...props }) => (
  <div className={styles.fromMd} {...props} />
);

export const ContainerToMD = ({ className, ...props }) => (
  <div className={styles.toMd} {...props} />
);

export const ContainerFromLG = ({ className, ...props }) => (
  <div className={styles.fromLg} {...props} />
);

export const ContainerToLG = ({ className, ...props }) => (
  <div className={styles.toLg} {...props} />
);
