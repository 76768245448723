import { forwardRef, useState, useEffect, useContext, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Marker, Icon } from 'leaflet';

import { MapContext } from './map-container';
import { MAP_MARKER_DEFAULT_OPTIONS } from './options';

// export const MAP_MARKER_DEFAULT_OPTIONS = {
//   icon: new Icon({
//     iconUrl: Pin,
//     iconSize: [50, 75],
//   }),
// };

export const MapMarker = forwardRef(({ latLng, options, onInitialized }, ref) => {
  const [marker, setMarker] = useState(null);
  const map = useContext(MapContext);

  useImperativeHandle(ref, () => marker, [marker]);

  useEffect(() => {
    if (map === null) {
      return;
    }

    const icon = new Icon(options);
    const marker = new Marker(latLng, {
      icon,
    });
    setMarker(marker);

    map.addLayer(marker);

    const teardown = onInitialized(map, marker);

    return () => {
      if (typeof teardown === 'function') {
        teardown();
      }

      marker.remove();
      setMarker(null);
    };
  }, [map, latLng, options, onInitialized]);

  return null;
});

MapMarker.defaultProps = {
  latLng: null,
  options: MAP_MARKER_DEFAULT_OPTIONS,
  onInitialized: (/* map, marker */) => {},
};

MapMarker.propTypes = {
  latLng: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.shape({
    iconUrl: PropTypes.string,
    iconSize: PropTypes.arrayOf(PropTypes.number),
  }),
  onInitialized: PropTypes.func,
};
