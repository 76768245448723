import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticQuickinfo = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryQuickinfoContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useQuickinfoQuery {
		squidex {
			queryQuickinfoContents {
				id
				data {
					mail {
						de {
							title
							text
							link {
								data {
									path {
										de
									}
								}
							}
							linkText
						}
					}
					phone {
						de {
							title
							text
						}
					}
					condominium {
						de {
							title
							text
						}
					}
				}
			}
		}
	}
`;
