import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { debounce } from 'utils';
import { Tree } from 'components';

import { Search } from './search';

import styles from './auto-complete.module.scss';

export const AutoComplete = ({
  items,
  maxResults,
  value,
  focus,
  placeholder,
  noResults,
  onSearch,
  onSelect,
  ...props
}) => {
  const mountRef = useRef(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [selectedNode, setSelectedNode] = useState(null);
  const [isTreeVisible, setIsTreeVisible] = useState(false);

  const inputHandler = useCallback(
    debounce((value) => {
      if (!mountRef.current) {
        return;
      }

      if (!value) {
        setSelectedNode(null);
        onSelect(null);
      }

      setIsTreeVisible(!!value);
      setCurrentValue(value);
      onSearch(value);
    }),
    [onSearch]
  );

  const selectNodeHandler = useCallback(
    (node) => {
      setIsTreeVisible(false);
      setSelectedNode(node);
      setCurrentValue(node.text);

      onSelect(node);
    },
    [onSelect]
  );

  useEffect(() => {
    mountRef.current = true;
    return () => {
      mountRef.current = false;
    };
  });

  useEffect(() => {
    inputHandler(value);
  }, [inputHandler, value]);

  return (
    <div className={styles.wrapper}>
      <Search
        focus={focus}
        value={currentValue}
        onInput={inputHandler}
        placeholder={placeholder}
      />

      {isTreeVisible && (
        <div className={styles.tree}>
          <Tree
            nodes={items}
            query={currentValue}
            autoCloseOpened={true}
            maxResults={maxResults}
            selectedNode={selectedNode}
            onSelectNode={selectNodeHandler}
            {...props}>
            {noResults}
          </Tree>
        </div>
      )}
    </div>
  );
};

AutoComplete.defaultProps = {
  items: [],
  selectedNode: null,
  maxResults: 15,
  value: '',
  focus: false,
  placeholder: 'Search...',
  noResults: 'No results found.',
  onSelect: (/* item */) => {},
  onSearch: (/* query */) => {},
};

AutoComplete.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  maxResults: PropTypes.number,
  value: PropTypes.string,
  focus: PropTypes.bool,
  placeholder: PropTypes.string,
  noResults: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
};
