import { graphql, useStaticQuery } from 'gatsby';

import { useFlatten } from './use-flatten';
import { getProperty } from 'utils';

export const useStaticNews = () => {
	const results = useStaticQuery(query) || {};
	const key = 'squidex.queryNewsContents.0';

	return useFlatten(getProperty(results, key) || {});
};

const query = graphql`
	query useNewsQuery {
		squidex {
			queryNewsContents {
				id
				data {
					seo {
						de {
							title
							description
						}
					}

					archive {
						de {
							backgroundImage {
								metadata
								localFile {
									publicURL
								}
								...SquidexImageLocalFileProps
							}
							title
							latestText
							olderText
						}
					}

					article {
						de {
							furtherImages
						}
					}
				}
			}
		}
	}
`;
