export const isObject = (value) => typeof value === 'object' && value !== null;

export const getProperty = (object, path) => {
  if (!isObject(object)) {
    // throw new Error(`"object" must be an object!`);
    return null;
  }

  if (typeof path !== 'string') {
    // throw new Error(`"path" must be a string!`);
    return null;
  }

  return path
    .split('.')
    .reduce((result, key) => (isObject(result) ? result[key] : result), object);
};

export const setProperty = (object, path, value) => {
  if (!isObject(object)) {
    // throw new Error(`"object" must be an object!`);
    return;
  }

  if (typeof path !== 'string') {
    // throw new Error(`"path" must be a string!`);
    return;
  }

  if (typeof value === 'undefined') {
    // throw new Error(`"value" must not be undefined!`);
    return;
  }

  path = path.split('.');

  return path.reduce((result, key, index) => {
    if (index === path.length - 1) {
      result[key] = value;
      return value;
    }

    if (!isObject(result[key])) {
      result[key] = {};
    }

    return result[key];
  }, object);
};

export const removeProperty = (object, property) => {
  if (!isObject(object)) {
    // throw new Error(`"object" must be an object!`);
    return;
  }

  if (typeof property !== 'string') {
    // throw new Error(`"property" must be a string!`);
    return;
  }

  const { [property]: _, ...rest } = object;

  return rest;
};
